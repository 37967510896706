import { ROUTES } from 'core/const/routes';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import LandingPageLayout from '../../../component/layouts/LandingPageLayout';
import FirstSection from 'component/organisms/landing-page-sections/FirstSection';
import Headline, { HEADLINE_SIZE } from 'component/atoms/typographie/Headline';
import CTACard from 'component/molecules/Cards/CTACard';
import { TEXT_COLOR } from 'component/atoms/typographie/Text';
import { IMAGES } from 'core/const/images';
import ProSectionRealEstateAgent from 'component/organisms/landing-page-sections/ProSection/ProSectionRealEstateAgent';
import FAQSection from 'component/organisms/landing-page-sections/FAQSection';
import { USER_TYPE } from '@wohnsinn/ws-ts-lib';
import ContactSection from 'component/organisms/landing-page-sections/ContactSection';
import CustomerTestimonialsSection from 'component/organisms/landing-page-sections/CustomerTestimonialsSection';
import LogoSlider from 'component/atoms/LogoSlider';
import { CLIENT_LOGOS } from 'view/landing-page/LandingPageView';
import MaxReachSection from 'component/organisms/landing-page-sections/MaxReachSection';
import { MODAL_IDENTIFIER } from 'core/enum/modals.enum';
import { useContext } from 'react';
import ModalContext from 'core/context/modal.context';

const RealEstateAgentView = () => {
  const { t: r } = useTranslation('routes');
  const { t: i } = useTranslation('images');
  const { openModal } = useContext(ModalContext);

  return (
    <LandingPageLayout pageTitle={r(ROUTES.landingPageRoutes.realEstateAgent.title)}>
      <Grid
        item
        xs={12}
        paddingTop={{ xs: 4, md: 10 }}
        paddingBottom={{ xs: 4, md: 10 }}
        className={'ws-full-width-bg'}
      >
        <FirstSection
          title={'Endlich wieder profitabel vermieten'}
          ctaText={'Kostenlos Objekt inserieren'}
          ctaOnClick={() => openModal({ id: MODAL_IDENTIFIER.SIGN_UP_USER, data: { isProbablyTenant: false } })}
          thumbnailId={'hljr4dorh0'}
          videoId={'aeruvismvj'}
        />
      </Grid>
      <Grid item xs={12} paddingTop={{ xs: 4, md: 10 }} paddingBottom={{ xs: 4, md: 10 }}>
        <Grid container justifyContent={'center'}>
          <Grid item xs={12} md={8}>
            <Headline size={HEADLINE_SIZE.DISPLAY} align={'center'}>
              Kommen dir diese Probleme bekannt vor?
            </Headline>
          </Grid>
        </Grid>

        <Grid container spacing={4} paddingTop={{ xs: 4, md: 10 }} paddingBottom={{ xs: 4, md: 10 }}>
          <Grid item xs={12} md={4}>
            <CTACard
              imgHeight={48}
              headlineSize={HEADLINE_SIZE.H3}
              title={'Zu viele unqualifizierte Mietanfragen'}
              text={
                'Mietinteressenten bewerben sich auf deine Wohnung, obwohl sie nicht deinen Anforderungen entsprechen.'
              }
              textColor={TEXT_COLOR.TEXT_COLOR_ACCENT}
              imgSrc={i(IMAGES.pictograms.thumbDown.src)}
              imgAltText={i(IMAGES.pictograms.thumbDown.src)}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CTACard
              imgHeight={48}
              headlineSize={HEADLINE_SIZE.H3}
              title={'Überfülltes E-Mail Postfach'}
              text={
                'Kurz nach der Veröffentlichung läuft dein Postfach über und du hast Mühe, den Überblick zu behalten.'
              }
              textColor={TEXT_COLOR.TEXT_COLOR_ACCENT}
              imgSrc={i(IMAGES.pictograms.openMail.src)}
              imgAltText={i(IMAGES.pictograms.openMail.src)}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CTACard
              imgHeight={48}
              headlineSize={HEADLINE_SIZE.H3}
              title={'Hohe Inseratskosten auf anderen Portalen'}
              text={'Andere Portale verlangen hohe Inseratskosten, ohne die Vermietung effizient zu unterstützen.'}
              textColor={TEXT_COLOR.TEXT_COLOR_ACCENT}
              imgSrc={i(IMAGES.pictograms.lock.src)}
              imgAltText={i(IMAGES.pictograms.lock.src)}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        className={'ws-full-width-bg'}
        paddingTop={{ xs: 4, md: 10 }}
        paddingBottom={{ xs: 4, md: 10 }}
      >
        <ProSectionRealEstateAgent />
      </Grid>

      <Grid
        item
        xs={12}
        className={`ws-full-width-bg `}
        paddingTop={{ xs: 4, md: 10 }}
        paddingBottom={{ xs: 4, md: 10 }}
      >
        <MaxReachSection />
      </Grid>

      <CustomerTestimonialsSection />

      <Grid container justifyContent={'center'}>
        <Grid
          item
          xs={12}
          md={8}
          className={'ws-full-width-bg ws-full-width-bg--white'}
          justifyContent={'center'}
          paddingTop={{ xs: 4, md: 10 }}
          paddingBottom={{ xs: 4, md: 10 }}
        >
          <Headline size={HEADLINE_SIZE.DISPLAY} align={'center'}>
            Täglich von kleinen und großen Vermietern genutzt
          </Headline>
        </Grid>
      </Grid>

      <Grid item xs={12} className={'ws-full-width-bg ws-full-width-bg--white'} paddingBottom={{ xs: 4, md: 10 }}>
        <LogoSlider items={CLIENT_LOGOS}></LogoSlider>
      </Grid>

      <Grid
        item
        xs={12}
        paddingTop={{ xs: 4, md: 10 }}
        paddingBottom={{ xs: 4, md: 10 }}
        className={'ws-full-width-bg ws-full-width-bg--white'}
      >
        <CTACard
          headlineSize={HEADLINE_SIZE.H1}
          alternativeStyle
          title={'Jetzt kostenlos inserieren'}
          text={'Über 150 Verwalter nutzen bereits Wohnsinn.'}
          ctaText={'Objekt inserieren'}
          shrinkButton
          onClick={() => openModal({ id: MODAL_IDENTIFIER.SIGN_UP_USER, data: { isProbablyTenant: false } })}
        />
      </Grid>

      <Grid
        item
        xs={12}
        paddingTop={{ xs: 4, md: 10 }}
        paddingBottom={{ xs: 4, md: 10 }}
        className={'ws-full-width-bg ws-full-width-bg--white'}
      >
        <ContactSection />
      </Grid>

      <Grid
        item
        className={'ws-full-width-bg ws-full-width-bg--white'}
        xs={12}
        paddingTop={{ xs: 4, md: 10 }}
        paddingBottom={{ xs: 8, md: 20 }}
      >
        <FAQSection userType={USER_TYPE.LANDLORD} />
      </Grid>
    </LandingPageLayout>
  );
};

export default RealEstateAgentView;
