import { FC } from 'react';
import styles from './ApartmentCardApartmentInformation.module.scss';
import Text, { TEXT_COLOR } from 'component/atoms/typographie/Text';
import Headline, { HEADLINE_COLOR, HEADLINE_SIZE } from 'component/atoms/typographie/Headline';
import { useTranslation } from 'react-i18next';
import { getFormattedPrice } from 'core/helper/get-formatted-price';
import { IApartmentCardInformation } from '../../ApartmentCard';

const ApartmentCardApartmentInformation: FC<{
  apartmentCardInformation: IApartmentCardInformation;
  clickHandler: () => void;
}> = ({ apartmentCardInformation, clickHandler }) => {
  const { cost, rooms, address, size } = apartmentCardInformation;
  const { t } = useTranslation('common');
  return (
    <button onClick={clickHandler} type={'button'} className={styles.content}>
      <div className={styles.bar}>
        <Text tag={'span'} color={TEXT_COLOR.TEXT_COLOR_ACCENT}>
          {address.postalCode} {address.city}
        </Text>
        <Text tag={'span'} color={TEXT_COLOR.TEXT_COLOR_ACCENT}>
          {rooms ? `${rooms} ${t('rooms')}` : ''}
          {rooms && size ? ' · ' : ''}
          {size ? `${size} ${t('qm')}` : ''}
        </Text>
      </div>

      <div className={styles.bar}>
        <Headline size={HEADLINE_SIZE.H2} color={HEADLINE_COLOR.HEADLINE_COLOR_PRIMARY}>
          {getFormattedPrice(cost?.warmRent, cost?.coldRent)}
        </Headline>

        <Headline size={HEADLINE_SIZE.H3} color={HEADLINE_COLOR.HEADLINE_COLOR_DARK} underline={true}>
          {'Mehr Infos'}
        </Headline>
      </div>
    </button>
  );
};

export default ApartmentCardApartmentInformation;
