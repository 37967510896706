import { FC, useContext, useEffect, useState } from 'react';
import useApartment from '../../../core/hook/apartment.hook';
import ApartmentInformation from '../../../component/organisms/ApartmentInformation';
import MapboxMap from '../../../component/molecules/Map';
import { ROUTES } from 'core/const/routes';
import { useTranslation } from 'react-i18next';
import styles from './ShareApartmentView.module.scss';
import useWindowSize from '../../../core/hook/windowsize.hook';
import UserContext from '../../../core/context/user.context';
import { MATCHING_MODE } from '@wohnsinn/ws-ts-lib';
import LOCAL_STORAGE_KEYS from '../../../core/enum/local-storage.enum';
import { MODAL_IDENTIFIER } from 'core/enum/modals.enum';
import ModalContext from '../../../core/context/modal.context';
import { motion } from 'framer-motion';
import { wohnsinnServices } from 'App';
import SnackBarContext from '../../../core/context/snackbar.context';
import { Drawer, Grid } from '@mui/material';
import CTACard from '../../../component/molecules/Cards/CTACard';
import ApartmentStackPageLayout from '../../../component/layouts/ApartmentStackPageLayout';
import { getTenantApplicationState, TENANT_APPLICATION_STATE } from 'core/helper/get-tenant-application-state';
import { animateToLeft, animateToRight, initial } from 'core/const/matching-animations';
import ApartmentInformationLoading from '../../../component/molecules/LoadingElements/ApartmentInformationLoading';
import ApartmentRatingButtons from '../../../component/atoms/ApartmentRatingButtons';
import { useNavigate, useParams } from 'react-router-dom';
import useDesiredTenant from 'core/hook/desired-tenant.hook';
import PremiumSubscriptionDrawer from 'component/atoms/PremiumSubscriptionDrawer';
import ApplicationListContext from 'core/context/application-list.context';
export type TAnimationDirection = 'left' | 'right' | 'initial';

const ShareApartmentView: FC = () => {
  const { likeList } = useContext(ApplicationListContext);
  const { apartment, isLoading } = useApartment();
  const { apartmentId } = useParams<{ apartmentId: string }>();
  const application = likeList.find((application) => application.apartmentId === apartmentId);
  const { handleSnackBar } = useContext(SnackBarContext);
  const { openModal } = useContext(ModalContext);
  const { applicationService, mixpanelTrackingService } = wohnsinnServices;
  const { t } = useTranslation('common');
  const { t: r } = useTranslation('routes');
  const { t: v } = useTranslation('common', { keyPrefix: 'view.ShareApartmentView' });
  const { isGreaterMd } = useWindowSize();
  const navigate = useNavigate();
  const { user, tenantProfile, isPremiumUser } = useContext(UserContext);
  const [animationDirection, setAnimationDirection] = useState<TAnimationDirection>('initial');
  const { desiredTenantErrors } = useDesiredTenant(apartment, tenantProfile);
  const [showSubscriptionDrawer, setShowSubscriptionDrawer] = useState<boolean>(false);

  useEffect(() => {
    if (apartment) {
      if (user) {
        mixpanelTrackingService.trackEnterSharedView({
          pageTitle: 'ShareViewWithUser',
          id: apartment.id,
          isExclusive: !!apartment?.isExclusive,
        });
      } else {
        mixpanelTrackingService.trackEnterSharedView({
          pageTitle: 'ShareViewAnonym',
          id: apartment.id,
          isExclusive: !!apartment?.isExclusive,
        });
      }
    }
  }, [apartment]);

  useEffect(() => {
    if (apartment && !apartment.isPublished) {
      handleSnackBar('toast.apartment.notAvailable', 'warning');
    }
  }, [apartment]);

  const submitRatingHandler = async (rating: MATCHING_MODE) => {
    mixpanelTrackingService.trackRatingClick(rating, apartment.id);
    setAnimationDirection(rating === MATCHING_MODE.NOPE ? 'left' : 'right');
    const showApplicationTunnel = Math.random() > 0.5;

    if (user) {
      await createApplication(rating);
    } else {
      if (!user) {
        localStorage.setItem(LOCAL_STORAGE_KEYS.APARTMENT_ID, apartment?.id);
        mixpanelTrackingService.trackEvent('ABTest', { showApplicationTunnel });
        if (showApplicationTunnel) {
          setTimeout(() => {
            setAnimationDirection('initial');
            navigate(
              r(ROUTES.staticRoutes.createTenantFromApartmentApplyTunnel.path).replace(':apartmentId', apartment?.id)
            );
            return;
          }, 300);
        } else {
          setTimeout(() => {
            setAnimationDirection('initial');
            openModal({
              id: MODAL_IDENTIFIER.SIGN_UP_USER,
              data: {
                title: 'Erstelle ein Konto, um dich zu bewerben',
                isProbablyTenant: true,
              },
            });
          }, 300);
        }
      }
    }
  };

  const createApplication = async (rating: MATCHING_MODE) => {
    const tenantApplicationState = getTenantApplicationState(tenantProfile);

    localStorage.setItem(
      LOCAL_STORAGE_KEYS.REDIRECT_URL,
      `${r(ROUTES.staticRoutes.shareApartment.path).replace(':apartmentId', apartment.id)}`
    );

    if (rating === MATCHING_MODE.LIKE) {
      if (tenantApplicationState === TENANT_APPLICATION_STATE.NOT_READY_FOR_APPLICATION) {
        setAnimationDirection('right');
        setTimeout(() => {
          setAnimationDirection('initial');
          openModal({ id: MODAL_IDENTIFIER.COMPLETE_APPLICATION_FOLDER });
        }, 300);
        return;
      }
    }

    if (desiredTenantErrors?.length) {
      setTimeout(() => {
        setAnimationDirection('initial');
        openModal({ id: MODAL_IDENTIFIER.DESIRED_TENANT, data: desiredTenantErrors });
        mixpanelTrackingService.trackOpenModal(MODAL_IDENTIFIER.DESIRED_TENANT, apartment.id);
        localStorage.removeItem(LOCAL_STORAGE_KEYS.APARTMENT_ID);
      }, 300);
      return;
    }
    if (!isPremiumUser && apartment?.isExclusive) {
      setTimeout(() => {
        setAnimationDirection('initial');
        setShowSubscriptionDrawer(true);
        mixpanelTrackingService.trackEnterPage('PRICING_DRAWER', apartment.id);
      }, 300);
      return;
    }

    if (tenantApplicationState === TENANT_APPLICATION_STATE.MISSING_DOCS) {
      setAnimationDirection('right');
      setTimeout(() => {
        setAnimationDirection('initial');
        openModal({ id: MODAL_IDENTIFIER.COMPLETE_DOCUMENT_FOLDER });
      }, 300);
      return;
    }
    try {
      localStorage.setItem(LOCAL_STORAGE_KEYS.REDIRECT_URL, '/');
      localStorage.removeItem(LOCAL_STORAGE_KEYS.APARTMENT_ID);
      await applicationService.handleRating(apartment, tenantProfile, rating, true);

      if (rating === MATCHING_MODE.LIKE) {
        await applicationService.createApplication(apartment, tenantProfile, rating);
        handleSnackBar('toast.success.applied', 'success');
      }

      redirectAfterRating();
    } catch (error) {
      console.error(error);
    }
  };

  const redirectAfterRating = () => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.REDIRECT_URL, '/');
    navigate(r(ROUTES.staticRoutes.redirect.path));
  };

  if (!isLoading && !apartment) {
    return (
      <ApartmentStackPageLayout backButtonRoute={'/'} pageTitle={r(ROUTES.landlordRoutes.apartment.preview.title)}>
        <Grid container justifyContent={'center'}>
          <Grid item xs={12} md={6}>
            <div className={styles.notExistingApartmentWrapper}>
              <CTACard
                title={v('apartmentNotExisting.title')}
                text={v('apartmentNotExisting.text')}
                imgSrc={t('pictogram.object')}
                imgAltText={t('bookmarkIcon')}
                link={'/'}
                ctaText={'Zur Übersicht'}
              />
            </div>
          </Grid>
        </Grid>
      </ApartmentStackPageLayout>
    );
  }

  if (isLoading) {
    return (
      <ApartmentStackPageLayout backButtonRoute={'/'} pageTitle={r(ROUTES.landlordRoutes.apartment.preview.title)}>
        <ApartmentInformationLoading />
      </ApartmentStackPageLayout>
    );
  }

  return (
    <>
      {
        <Drawer open={showSubscriptionDrawer} anchor={'right'} sx={{ width: '100vw' }}>
          <PremiumSubscriptionDrawer closeAction={() => setShowSubscriptionDrawer(false)}></PremiumSubscriptionDrawer>
        </Drawer>
      }
      <ApartmentStackPageLayout backButtonRoute={'/'} pageTitle={r(ROUTES.landlordRoutes.apartment.preview.title)}>
        {apartment ? (
          <div className={styles.wrapper}>
            <motion.div
              key={apartment?.id}
              className={styles.apartment}
              style={{
                zIndex: 10,
              }}
              animate={getAnimation(true, animationDirection)}
            >
              <ApartmentInformation apartment={apartment} />
            </motion.div>
            <div className={styles.ratings}>
              {apartment?.isPublished ? (
                <ApartmentRatingButtons
                  isApplicationView={false}
                  apartment={apartment}
                  disabled={!apartment?.isPublished}
                  onButtonClick={(matchingMode) => submitRatingHandler(matchingMode)}
                />
              ) : null}
            </div>
            <div className={styles.map}>
              {!isLoading && !!apartment && isGreaterMd && <MapboxMap isInteractive={true} apartments={[apartment]} />}
            </div>
          </div>
        ) : null}
      </ApartmentStackPageLayout>
    </>
  );
};

/**
 * Get styles for rating animations
 * @param {boolean} canAnimate
 * @param  {TAnimationDirection} animationDirection
 */
export const getAnimation = (canAnimate: boolean, animationDirection: TAnimationDirection) => {
  switch (canAnimate && animationDirection) {
    case 'initial':
      return initial;
    case 'left':
      return animateToLeft;
    case 'right':
      return animateToRight;
  }
};

export default ShareApartmentView;
