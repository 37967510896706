import { SALUTATION_TYPES } from 'component/modals/MultiMessageModal';
import { ISelectOption } from '@wohnsinn/ws-ts-lib';

export const getSalutationList = (t: any): ISelectOption<SALUTATION_TYPES>[] => {
  return [
    { value: SALUTATION_TYPES.HELLO, label: t('salutation.hello') },
    { value: SALUTATION_TYPES.POLITE, label: t('salutation.polite') },
    { value: SALUTATION_TYPES.NONE, label: t('salutation.none') },
  ];
};
