import { FC, useContext, useEffect, useState } from 'react';
import styles from './ApplicantInformationSection.module.scss';
import {
  getJobStatusItems,
  getLegalItems,
  getPersonalInformationItems,
  getSelfDisclosureItems,
} from '../../../../core/helper/get-applicant-informations-helper';
import { IApplication, ITenantProfile, IUpload } from '@wohnsinn/ws-ts-lib';
import { FormContext } from 'core/context/form.context';

import useTenantDocuments from '../../../../core/hook/tenant-document.hook';
import { useTranslation } from 'react-i18next';
import Headline, { HEADLINE_SIZE } from '../../../atoms/typographie/Headline';
import SanitizedHTML from '../../../atoms/SanitzedHtml';
import Text, { TEXT_COLOR, TEXT_TYPE, TEXT_WEIGHT } from '../../../atoms/typographie/Text';
import ItemList from '../../../molecules/ItemList';
import DownloadItemsList from '../../../molecules/DownloadItemsList';
import { Grid } from '@mui/material';
import Avatar, { AVATAR_SIZE } from '../../../atoms/Avatar';
import LightShow from '../../../molecules/LightShow';
import { MEDIA_TYPE } from '@wohnsinn/ws-ts-lib/dist/types/media';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/pro-light-svg-icons';
import Spacer, { SPACER_SIZE } from '../../../atoms/Spacer';
import TextEditor, { TEXT_EDITOR_SIZE } from 'component/atoms/formElement/TextEditor';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { SUBMIT_BUTTON_MODE } from 'core/enum/submit-button-mode.enum';
import { wohnsinnServices } from 'App';
import ModalContext from 'core/context/modal.context';
import CTAButton from 'component/atoms/Buttons/CTAButton';

const ApplicantInformationSection: FC<{
  tenantProfile: ITenantProfile;
  application: IApplication;
  closeAction?: () => any;
}> = ({ tenantProfile, application, closeAction }) => {
  const { t } = useTranslation('common', { keyPrefix: 'component.organisms.ApplicantInformation' });
  const { t: c } = useTranslation('common');
  const [buttonSubmitMode, setButtonSubmitMode] = useState(SUBMIT_BUTTON_MODE.NONE);
  const { applicationService } = wohnsinnServices;
  const { closeModal } = useContext(ModalContext);

  const submitForm = async () => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUBMITTING);
    const data: Partial<IApplication> = {
      notes: watch('notes'),
    };
    try {
      await applicationService.updateApplication({
        landlordId: application?.landlordId,
        apartmentId: application?.apartmentId,
        tenantId: application?.tenantProfile?.uid,
        data,
      });

      setButtonSubmitMode(SUBMIT_BUTTON_MODE.NONE);
      closeAction();
      closeModal();
    } catch (e) {
      setButtonSubmitMode(SUBMIT_BUTTON_MODE.ERROR);
      console.error('Error on invite user modal', e);
    }
  };
  const [schufaCheckDocumentUploadList, setSchufaCheckUploadList] = useState<IUpload[]>([]);
  const [incomeProofDocumentUploadList, setIncomeProofDocumentUploadList] = useState<IUpload[]>([]);
  const { schufaCheckDocumentList, incomeProofDocumentList } = useTenantDocuments(tenantProfile?.uid);
  const [isLightShowOpen, setIsLightShowOpen] = useState<boolean>(false);
  const Note_Scheme: any = {
    notes: Joi.string().optional(),
  };
  useEffect(() => {
    setSchufaCheckUploadList(schufaCheckDocumentList);
    setIncomeProofDocumentUploadList(incomeProofDocumentList);
  }, [schufaCheckDocumentList, incomeProofDocumentList]);
  const {
    control,
    watch,
    handleSubmit,
    formState: { isValid },
  } = useForm<{ notes?: string }>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: {
      notes: application?.notes,
    },
    resolver: joiResolver(Joi.object(Note_Scheme)),
  });
  return (
    <Grid container spacing={8} p={2}>
      {tenantProfile?.photoUrl ? (
        <LightShow
          open={isLightShowOpen}
          onClose={() => setIsLightShowOpen(false)}
          media={[
            {
              alt: tenantProfile?.personalInformation?.firstName,
              creatorId: 'non',
              id: 'string',
              mediaType: MEDIA_TYPE.IMAGE,
              thumbnailUrl: tenantProfile?.photoUrl,
              updatedAt: new Date(),
              url: tenantProfile?.photoUrl,
              width: 250,
            },
          ]}
        />
      ) : null}
      <Grid item xs={12}>
        <button onClick={() => setIsLightShowOpen(true)}>
          <Avatar bordered size={AVATAR_SIZE.xl} avatarUrl={tenantProfile?.photoUrl} />
          <Spacer />
        </button>
        <div className={styles.user}>
          {tenantProfile?.personalInformation?.firstName && tenantProfile?.personalInformation?.lastName ? (
            <Headline size={HEADLINE_SIZE.H1} tag={'span'}>
              {tenantProfile?.personalInformation?.firstName} {tenantProfile?.personalInformation?.lastName}
            </Headline>
          ) : null}{' '}
          <br />
          <FontAwesomeIcon icon={faEnvelope} />{' '}
          <a href={`mailto:${tenantProfile.email}`}>
            <Text tag={'span'} color={TEXT_COLOR.TEXT_COLOR_PRIMARY} className={TEXT_COLOR.TEXT_COLOR_ACCENT}>
              {tenantProfile.email}
            </Text>
          </a>
          <br />
          {tenantProfile.personalInformation?.phoneNumber ? (
            <>
              <FontAwesomeIcon icon={faPhone} />{' '}
              <a href={`tel:${tenantProfile.personalInformation.phoneNumber}`}>
                <Text tag={'span'} color={TEXT_COLOR.TEXT_COLOR_PRIMARY} className={TEXT_COLOR.TEXT_COLOR_ACCENT}>
                  {tenantProfile.personalInformation.phoneNumber}
                </Text>
              </a>
            </>
          ) : null}
        </div>
      </Grid>

      {tenantProfile && tenantProfile?.aboutMe?.introductionVideo ? (
        <Grid item xs={12}>
          {/* INTRODUCTION VIDEO */}
          <Headline size={HEADLINE_SIZE.H2}>{t('introductionVideo.title')}</Headline>
          <Spacer size={SPACER_SIZE.MD} />
          <video src={tenantProfile?.aboutMe.introductionVideo.url} className={styles.video} controls>
            <source src={tenantProfile?.aboutMe.introductionVideo.url} />
          </video>
        </Grid>
      ) : null}
      <Grid item xs={12}>
        {/* ABOUT */}
        <Headline size={HEADLINE_SIZE.H2}>{t('aboutMe')}</Headline>
        <Spacer size={SPACER_SIZE.MD} />
        <Text weight={TEXT_WEIGHT.TEXT_WEIGHT_LIGHT} italic type={TEXT_TYPE.TEXT_TYPE_CAPTION}>
          <SanitizedHTML
            className={styles.aboutMeText}
            options={{ allowedTags: ['br'] }}
            dirty={tenantProfile?.aboutMe.description}
          />
        </Text>
      </Grid>

      <Grid item xs={12}>
        {/* PERSONAL INFORMATION */}
        <Headline size={HEADLINE_SIZE.H2}>{c('personalInformation')}</Headline>
        <Spacer size={SPACER_SIZE.MD} />
        <ItemList items={getPersonalInformationItems(tenantProfile, c)} />
      </Grid>
      <Grid item xs={12}>
        {/* SELF DISCLOSURE */}
        <Headline size={HEADLINE_SIZE.H2}>{t('houseHold')}</Headline>
        <Spacer size={SPACER_SIZE.MD} />
        <ItemList items={getSelfDisclosureItems(tenantProfile, t)} />
      </Grid>
      <Grid item xs={12}>
        {/* JOB STATUS */}
        <Headline size={HEADLINE_SIZE.H2}>{t('jobStatus')}</Headline>
        <Spacer size={SPACER_SIZE.MD} />
        <ItemList items={getJobStatusItems(tenantProfile, t)} />
      </Grid>
      <Grid item xs={12}>
        {/* LEGAL ITEMS */}
        <Headline size={HEADLINE_SIZE.H2}>{t('legalItems')}</Headline>
        <Spacer size={SPACER_SIZE.MD} />
        <ItemList items={getLegalItems(tenantProfile, t, c)} />
      </Grid>
      {(schufaCheckDocumentUploadList && schufaCheckDocumentUploadList.length) ||
      (incomeProofDocumentUploadList && incomeProofDocumentUploadList.length) ? (
        <Grid item xs={12}>
          {/* DOCUMENTS */}
          <Headline size={HEADLINE_SIZE.H2}>{t('documents')}</Headline>
          <Spacer size={SPACER_SIZE.MD} />
          {schufaCheckDocumentUploadList && schufaCheckDocumentUploadList.length ? (
            <DownloadItemsList label={c('schufa')} list={schufaCheckDocumentUploadList} />
          ) : null}
          {incomeProofDocumentUploadList && incomeProofDocumentUploadList.length ? (
            <DownloadItemsList label={c('incomeProof')} list={incomeProofDocumentUploadList} />
          ) : null}
        </Grid>
      ) : null}
      <Grid item xs={12}>
        {/* LEGAL ITEMS */}
        <Headline size={HEADLINE_SIZE.H2}>{'Eigene Notizen'}</Headline>
        <Spacer size={SPACER_SIZE.MD} />
        <FormContext.Provider value={{ control }}>
          <form
            id={'notesForm'}
            autoComplete={'off'}
            onSubmit={handleSubmit(submitForm, (error) => console.error(error))}
          >
            <TextEditor hideToolbar={true} name={'notes'} size={TEXT_EDITOR_SIZE.MEDIUM} placeholder={c('notes')} />
          </form>
        </FormContext.Provider>
        <CTAButton
          buttonText={'Speichern'}
          expand={'full'}
          form={'notesForm'}
          disabled={buttonSubmitMode === SUBMIT_BUTTON_MODE.SUBMITTING}
        />
      </Grid>
    </Grid>
  );
};

export default ApplicantInformationSection;
