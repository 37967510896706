export const formatNumberGermanStyle = (num: number | string) => {
  num = typeof num === 'string' ? parseFloat(num) : num;
  return num.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
};

export const getFormattedPrice = (warmRent?: number, coldRent?: number) => {
  if (warmRent) {
    return `${formatNumberGermanStyle(warmRent)} €`;
  } else if (coldRent) {
    return `${formatNumberGermanStyle(coldRent)} €`;
  }

  return 'Keine Angabe';
};
