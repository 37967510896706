import { FC } from 'react';
import styles from './ApartmentCard.module.scss';
import { useInView } from 'react-intersection-observer';
import ApartmentCardMedia from './ApartmentCardMedia';
import { APPLICATION_STATE, IApartmentAddress, IApartmentCost, IApplication, IMedia } from '@wohnsinn/ws-ts-lib';
import ApartmentCardApartmentInformation from './ApartmentCardApartmentInformation';
import ApartmentBadge from '../../../atoms/ApartmentBadge';
import StatusBadge from 'component/atoms/StatusBadge';

export interface IApartmentCardInformation {
  address: IApartmentAddress;
  apartmentId: string;
  cost: IApartmentCost;
  isDisabled?: boolean;
  media: IMedia[] | IMedia;
  rooms: number;
  size: number;
}

const ApartmentCard: FC<{
  apartmentCardInformation: IApartmentCardInformation;
  clickHandler: () => void;
  isExclusive?: boolean;
  application?: IApplication;
}> = ({ apartmentCardInformation, clickHandler, isExclusive = false, application }) => {
  const { apartmentId, media, isDisabled } = apartmentCardInformation;
  const { ref, entry } = useInView({ trackVisibility: true, delay: 100, triggerOnce: true });
  const mediaIsArray = Array.isArray(media);

  const applicationIsDeleted =
    application?.applicationState === APPLICATION_STATE.DELETED ||
    application?.applicationState === APPLICATION_STATE.WILL_BE_DELETED;

  return (
    <div ref={ref} className={`${styles.wrapper} ${isDisabled || applicationIsDeleted ? styles.isDisabled : ''}`}>
      <ApartmentBadge apartmentIsExclusive={isExclusive} />
      {application?.applicationState ? <StatusBadge status={application.applicationState} /> : null}

      <div className={styles.mediaWrapper}>
        <ApartmentCardMedia
          apartmentId={apartmentId}
          media={media ? (mediaIsArray ? media : [media]) : null}
          isIntersecting={entry?.isIntersecting}
          onGalleryClick={clickHandler}
        />
      </div>

      <div className={styles.content}>
        <ApartmentCardApartmentInformation
          clickHandler={clickHandler}
          apartmentCardInformation={apartmentCardInformation}
        />
      </div>
    </div>
  );
};

export default ApartmentCard;
