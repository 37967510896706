import styles from './ApartmentRatingButtons.module.scss';
import { FC, useContext } from 'react';
import { APPLICATION_STATE, IApartment, IApplication, MATCHING_MODE } from '@wohnsinn/ws-ts-lib';
import CTAButton, { BUTTON_STYLE } from '../Buttons/CTAButton';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { ROUTES } from 'core/const/routes';
import { useNavigate } from 'react-router-dom';
import ApplicationListContext from 'core/context/application-list.context';
import { wohnsinnServices } from 'App';
import { faClock } from '@fortawesome/pro-light-svg-icons';

enum APARTMENT_RATING_BUTTONS_STATE {
  GO_TO_MESSAGES = 'GO_TO_MESSAGES',
  GO_TO_APPLICATIONS = 'GO_TO_APPLICATIONS',
  APPLY = 'APPLY',
  NOPE = 'NOPE',
  WAITING_FOR_RESPONSE = 'WAITING_FOR_RESPONSE',
  DISABLED = 'DISABLED',
  DELETED = 'DELETED',
}

export interface IApartmentRatingButtonsProps {
  onButtonClick: (matchingMode: any) => void;
  disabled?: boolean;
  apartment?: IApartment;
  isApplicationView: boolean;
}

const ApartmentRatingButtons: FC<IApartmentRatingButtonsProps> = ({
  onButtonClick,
  disabled = false,
  apartment,
  isApplicationView,
}) => {
  const { t: r } = useTranslation('routes');
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const { likeList } = useContext(ApplicationListContext);
  const { chatService } = wohnsinnServices;
  const application = likeList.find((application) => application.apartmentId === apartment.id);
  const buttonState = getApartmentRatingButtonState({ apartment, application, isApplicationView });

  if (buttonState === APARTMENT_RATING_BUTTONS_STATE.DELETED) {
    return (
      <div className={styles.wrapper}>
        <CTAButton
          rounded={false}
          disabled={true}
          buttonStyle={BUTTON_STYLE.SECONDARY}
          buttonText={t('Absage erhalten')}
          expand={'block'}
          size={'medium'}
        />
      </div>
    );
  }
  if (buttonState === APARTMENT_RATING_BUTTONS_STATE.NOPE) {
    return (
      <div className={styles.wrapper}>
        <CTAButton
          rounded={false}
          disabled={true}
          buttonStyle={BUTTON_STYLE.SECONDARY}
          buttonText={t('Du hast dieses Apartment entfernt')}
          expand={'block'}
          size={'medium'}
        />
      </div>
    );
  }

  if (buttonState === APARTMENT_RATING_BUTTONS_STATE.DISABLED) {
    return (
      <div className={styles.wrapper}>
        <CTAButton
          rounded={false}
          disabled={true}
          buttonText={t('apartmentIsDisabled')}
          expand={'block'}
          size={'medium'}
        />
      </div>
    );
  }

  if (buttonState === APARTMENT_RATING_BUTTONS_STATE.WAITING_FOR_RESPONSE) {
    return (
      <div className={styles.wrapper}>
        <CTAButton
          rounded={false}
          disabled={true}
          iconPosition={'start'}
          icon={faClock}
          buttonText={t('waitingForResponse')}
          expand={'block'}
          size={'medium'}
        />
      </div>
    );
  }

  if (buttonState === APARTMENT_RATING_BUTTONS_STATE.GO_TO_APPLICATIONS) {
    return (
      <div className={styles.wrapper}>
        <CTAButton
          rounded={false}
          disabled={disabled}
          buttonText={t('goToApplications')}
          expand={'block'}
          onClick={() => navigate(r(ROUTES.tenantRoutes.applications.path))}
          size={'medium'}
        />
      </div>
    );
  }

  if (buttonState === APARTMENT_RATING_BUTTONS_STATE.GO_TO_MESSAGES) {
    return (
      <div className={styles.wrapper}>
        <CTAButton
          rounded={false}
          disabled={disabled}
          buttonText={t('goToMessages')}
          expand={'block'}
          onClick={() => navigate(r(ROUTES.tenantRoutes.chat.path))}
          size={'medium'}
        />
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <CTAButton
            disabled={disabled}
            buttonStyle={BUTTON_STYLE.DANGER}
            buttonText={t('nope')}
            img={t('icons.heart_nope_white')}
            onClick={() => onButtonClick(MATCHING_MODE.NOPE)}
            rounded={false}
            expand={'block'}
            size={'medium'}
            hideText
          />
        </Grid>
        <Grid item xs={9}>
          <CTAButton
            disabled={disabled}
            rounded={false}
            expand={'block'}
            buttonText={t('apply')}
            customStyling={styles.buttonAnimation}
            onClick={() => onButtonClick(MATCHING_MODE.LIKE)}
            img={t('icons.heart_like')}
            size={'medium'}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export const getApartmentRatingButtonState = (params: {
  isApplicationView: boolean;
  apartment: IApartment;
  application?: IApplication;
}): APARTMENT_RATING_BUTTONS_STATE => {
  const { apartment, application, isApplicationView } = params;

  if (
    application?.applicationState === APPLICATION_STATE.WILL_BE_DELETED ||
    application?.applicationState === APPLICATION_STATE.DELETED
  ) {
    return APARTMENT_RATING_BUTTONS_STATE.DELETED;
  }

  if (!application) {
    return APARTMENT_RATING_BUTTONS_STATE.APPLY;
  }

  if (application && application.rating == MATCHING_MODE.LIKE) {
    if (application.isChatDisabled === false) {
      return APARTMENT_RATING_BUTTONS_STATE.GO_TO_MESSAGES;
    }

    if (isApplicationView) {
      if (application.applicationState === APPLICATION_STATE.SELECTED_TENANT) {
        return APARTMENT_RATING_BUTTONS_STATE.GO_TO_MESSAGES;
      }

      return APARTMENT_RATING_BUTTONS_STATE.WAITING_FOR_RESPONSE;
    }

    return APARTMENT_RATING_BUTTONS_STATE.GO_TO_APPLICATIONS;
  }

  if (application.rating === MATCHING_MODE.NOPE) {
    return APARTMENT_RATING_BUTTONS_STATE.NOPE;
  }

  if (!apartment?.isPublished) {
    return APARTMENT_RATING_BUTTONS_STATE.DISABLED;
  }
};

export default ApartmentRatingButtons;
