import { FC, PropsWithChildren } from 'react';
import styles from './ThreeColumnsPageLayout.module.scss';
import AppHeader from '../../molecules/AppHeader';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

export interface ILayoutProps {
  headerEndButton?: any;
  pageTitle: string;
  secondPageTitle?: string;
  backButton?: any;
}

const ThreeColumnsPageLayout: FC<PropsWithChildren<ILayoutProps>> = ({
  children,
  headerEndButton,
  pageTitle,
  backButton,
}) => {
  const { t } = useTranslation('common');

  return (
    <div className={styles.appWrapper}>
      <div id="lightshow-portal" />
      <Helmet>
        <title>{`${t('appTitle')} - ${t(pageTitle)}`}</title>
      </Helmet>

      <header className={styles.appHeader}>
        <AppHeader customBackButton={backButton} pageTitle={pageTitle} headerEndButton={headerEndButton} />
      </header>
      <main className={styles.children}>{children}</main>
    </div>
  );
};

export default ThreeColumnsPageLayout;
