import { FC } from 'react';
import Dropdown from 'component/atoms/formElement/Dropdown';
import TextEditor, { TEXT_EDITOR_SIZE } from 'component/atoms/formElement/TextEditor';
import { getSalutationList } from 'core/const/salutation-list';
import { useTranslation } from 'react-i18next';

const MultiMessageFormComponents: FC = () => {
  const { t } = useTranslation('common');
  const SALUTATION_OPTION_LIST = getSalutationList(t);

  return (
    <>
      <Dropdown
        label={t('multiMessage.salutation.label')}
        placeholder={t('multiMessage.salutation.placeholder')}
        name="salutation"
        optionList={SALUTATION_OPTION_LIST}
        required
      />
      <TextEditor
        label={t('multiMessage.yourMessage')}
        hideToolbar
        size={TEXT_EDITOR_SIZE.MEDIUM}
        name={'message'}
        placeholder={t('multiMessage.yourMessage')}
        required
      />
    </>
  );
};

export default MultiMessageFormComponents;
