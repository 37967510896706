import { FC } from 'react';
import styles from './CTAButton.module.scss';
import Text, { TEXT_COLOR, TEXT_TYPE, TEXT_VARIANT, TEXT_WEIGHT } from '../../typographie/Text';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faSpinner, faTimesCircle } from '@fortawesome/pro-light-svg-icons';
import { SUBMIT_BUTTON_MODE } from 'core/enum/submit-button-mode.enum';
import { Link } from 'react-router-dom';

export enum BUTTON_STYLE {
  DANGER = 'danger',
  DANGER_INVERTED = 'danger-inverted',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  TERTIARY_LIGHT = 'tertiary-light',
}

export interface ICTAButtonProps {
  buttonStyle?: BUTTON_STYLE;
  buttonText: string;
  color?: TEXT_COLOR;
  customStyling?: any;
  disabled?: boolean;
  expand?: 'block' | 'full';
  expandMobile?: boolean;
  form?: string;
  hideText?: boolean;
  href?: string;
  icon?: IconProp;
  id?: string;
  iconPosition?: 'start' | 'end';
  img?: string;
  link?: string;
  mobileNotRounded?: boolean;
  notificationBadge?: boolean;
  onClick?: any;
  rounded?: boolean;
  size?: 'tiny' | 'small' | 'medium' | 'big' | 'extra' | 'normal';
  spinIcon?: boolean;
  tabIndex?: number;
  tag?: 'div' | 'button';
  target?: string;
  type?: 'submit' | 'button' | 'reset';
}

const CTAButton: FC<ICTAButtonProps> = ({
  buttonStyle = BUTTON_STYLE.PRIMARY,
  buttonText,
  color,
  customStyling,
  disabled,
  expand,
  expandMobile = false,
  form,
  hideText,
  href,
  icon,
  id,
  iconPosition = 'start',
  img,
  link,
  mobileNotRounded = false,
  notificationBadge,
  onClick,
  rounded = true,
  size = 'normal',
  spinIcon = false,
  tabIndex,
  tag = 'button',
  target,
  type = 'submit',
}) => {
  const Tag = tag;

  const renderButtonContent = () => (
    <div className={styles.content}>
      {notificationBadge ? <div className={styles.badge} /> : null}
      {img ? (
        <img
          alt={buttonText}
          className={`
          ${styles.img} 
          ${hideText ? styles.hideText : ''} 
          ${size === 'big' ? styles.big : ''} 
          ${size === 'medium' ? styles.medium : ''}`}
          src={img}
        />
      ) : null}

      {icon && iconPosition === 'start' ? (
        <div className={`${styles.icon} ${hideText ? styles.hideText : ''} ${size === 'big' ? styles.big : ''}`}>
          <FontAwesomeIcon
            spin={spinIcon}
            width={size === 'tiny' ? '12px' : '23px'}
            height={size === 'tiny' ? '12px' : '23px'}
            icon={icon}
            size={'1x'}
          />
        </div>
      ) : null}
      {hideText ? (
        <span className={'sr-only'}>{buttonText}</span>
      ) : (
        <div className={styles.text}>
          <Text
            tag={'span'}
            color={
              color
                ? color
                : buttonStyle === BUTTON_STYLE.TERTIARY_LIGHT
                ? TEXT_COLOR.TEXT_COLOR_LIGHT
                : BUTTON_STYLE.PRIMARY || buttonStyle === BUTTON_STYLE.DANGER
                ? TEXT_COLOR.TEXT_COLOR_LIGHT
                : TEXT_COLOR.TEXT_COLOR_DARK
            }
            type={size === 'tiny' ? TEXT_TYPE.TEXT_TYPE_CAPTION : TEXT_TYPE.TEXT_TYPE_BODY}
            variant={size === 'small' ? TEXT_VARIANT.TEXT_VARIANT_SMALL : TEXT_VARIANT.TEXT_VARIANT_LARGE}
            weight={
              size === 'small'
                ? TEXT_WEIGHT.TEXT_WEIGHT_REGULAR
                : size === 'extra'
                ? TEXT_WEIGHT.TEXT_WEIGHT_BOLD
                : TEXT_WEIGHT.TEXT_WEIGHT_BOLD
            }
          >
            {buttonText}
          </Text>
        </div>
      )}
      {icon && iconPosition === 'end' && (
        <div
          className={`${styles.icon} ${styles.end} ${hideText ? styles.hideText : ''} ${
            size === 'big' ? styles.big : ''
          }`}
        >
          <FontAwesomeIcon
            spin={spinIcon}
            width={size === 'tiny' ? '12px' : '23px'}
            height={size === 'tiny' ? '12px' : '23px'}
            icon={icon}
            size={'1x'}
          />
        </div>
      )}
    </div>
  );

  if (href) {
    return (
      <a
        className={`
        ${styles.ctaButton} 
        ${styles[size]} 
        ${styles[buttonStyle]} 
        ${customStyling ? customStyling : ''} 
        ${hideText ? styles.hideText : ''}
        ${disabled ? styles.disabled : ''}
        ${expandMobile ? styles.expandMobile : ''}
        ${styles.linkButton}
        ${rounded ? styles.rounded : ''}
        ${mobileNotRounded ? styles.mobileNotRounded : ''}
        ${expand ? styles.expand : ''}
      `}
        id={id}
        href={href}
        rel={target === '_blank' ? 'noreferrer noopener' : ''}
        target={target}
        title={buttonText}
      >
        {renderButtonContent()}
      </a>
    );
  }

  if (link) {
    return (
      <Link
        className={`
        ${styles.ctaButton} 
        ${styles[size]} 
        ${styles[buttonStyle]} 
        ${customStyling ? customStyling : ''} 
        ${hideText ? styles.hideText : ''}
        ${disabled ? styles.disabled : ''}
        ${expandMobile ? styles.expandMobile : ''}
        ${styles.linkButton}
        ${rounded ? styles.rounded : ''}
        ${mobileNotRounded ? styles.mobileNotRounded : ''}
        ${styles[color]} 
        ${expand ? styles.expand : ''}
      `}
        to={link ? (link as string) : (href as string)}
        target={target}
        id={id}
        title={buttonText}
        onClick={onClick}
      >
        {renderButtonContent()}
      </Link>
    );
  }

  return (
    <Tag
      form={form}
      tabIndex={tabIndex}
      id={id}
      className={`
        ${styles.ctaButton} 
        ${styles[size]} 
        ${styles[buttonStyle]} 
        ${customStyling ? customStyling : ''} 
        ${hideText ? styles.hideText : ''}
        ${expandMobile ? styles.expandMobile : ''}
        ${rounded ? styles.rounded : ''}
        ${mobileNotRounded ? styles.mobileNotRounded : ''}
        ${expand ? styles.expand : ''}
        ${disabled ? styles.disabled : ''}
        ${styles[color]} 
      `}
      data-testid={'ctaButton'}
      onClick={onClick}
      disabled={disabled}
      title={buttonText}
      type={type}
    >
      {renderButtonContent()}
    </Tag>
  );
};

export const getSubmitIcon = (submitMode: SUBMIT_BUTTON_MODE): IconProp => {
  switch (submitMode) {
    case SUBMIT_BUTTON_MODE.ERROR:
      return faTimesCircle;
    case SUBMIT_BUTTON_MODE.NONE:
      return null;
    case SUBMIT_BUTTON_MODE.SUBMITTING:
      return faSpinner;
    case SUBMIT_BUTTON_MODE.SUCCESS:
      return faCheckCircle;
    default:
      return null;
  }
};

export default CTAButton;
