import { arrayRemove, arrayUnion, Query } from 'firebase/firestore';
import {
  FIRESTORE_COLLECTION_PATH,
  IApplication,
  IChatMessage,
  ILandlordProfile,
  TApplicationCreate,
  TChatMessageCreate,
} from '@wohnsinn/ws-ts-lib';
import FirestoreService from './firestore.service';
import uuid4 from 'uuid4';
import { SALUTATION_TYPES } from 'component/modals/MultiMessageModal';

export interface IIsTyping {
  isLandlordTyping?: boolean;
  isTenantTyping?: boolean;
}

export class ChatService {
  constructor(private firestoreService: FirestoreService) {}

  public async createChat(application: TApplicationCreate) {
    const apartmentPath = `${FIRESTORE_COLLECTION_PATH.users.landlordProfiles.apartments.root
      .replace('{uid}', application.landlordId)
      .replace('{landlordId}', application.landlordId)}/${application.apartmentId}`;
    const applicationPath = `${apartmentPath}/applications/${application.tenantProfile.uid}`;
    const chatPath = `${applicationPath}/chatMessages/${application.tenantProfile.uid}`;

    const chatData: TChatMessageCreate = {
      apartmentId: application.apartmentId,
      landlordId: application.landlordId,
      senderAvatar: application.tenantProfile?.photoUrl,
      senderId: application.tenantProfile.uid,
      senderName:
        application?.tenantProfile?.personalInformation?.firstName &&
        application?.tenantProfile?.personalInformation?.lastName
          ? application?.tenantProfile?.personalInformation?.firstName +
            ' ' +
            application?.tenantProfile?.personalInformation?.lastName
          : '',
      tenantId: application.tenantProfile.uid,
      text: application.tenantProfile?.aboutMe.description,
      attachment: [],
    };

    return this.firestoreService.setDbDoc(chatData, chatPath, true, { setCreatedAt: true });
  }

  public getChatMessageListRef(tenantId: string, apartmentId: string, landlordId: string): Query<IChatMessage> {
    return this.firestoreService.getCollectionRefWithParams<IChatMessage>(
      this.firestoreService.getCollectionGroupRef('chatMessages', { fetchWithId: true }),
      {
        where: [
          { fieldPath: 'landlordId', opStr: '==', value: landlordId },
          { fieldPath: 'tenantId', opStr: '==', value: tenantId },
          { fieldPath: 'apartmentId', opStr: '==', value: apartmentId },
        ],
        orderBy: { fieldPath: 'createdAt', directionStr: 'asc' },
      }
    );
  }

  /**
   * Clear unread chats on apartment
   * @param {string} apartmentId
   * @param {string} landlordId
   * @param {string} tenantId
   */
  public async clearUnreadTenantChatsRefOnApartment(
    apartmentId: string,
    landlordId: string,
    tenantId: string
  ): Promise<void> {
    try {
      const batch = this.firestoreService.getBatch();
      const apartmentPath = `${FIRESTORE_COLLECTION_PATH.users.landlordProfiles.apartments.root
        .replace('{uid}', landlordId)
        .replace('{landlordId}', landlordId)}/${apartmentId}`;
      const apartmentDocRef = this.firestoreService.getDocRef(apartmentPath, { fetchWithId: true });

      batch.update(apartmentDocRef, { unreadTenantChatsRef: arrayRemove(tenantId) });

      await batch.commit();
    } catch (error) {
      console.error('Error on clearUnreadTenantChatsRefOnApartment: ', error);
    }
  }

  /**
   * Clear unread messages from tenants on application
   */
  public async clearUnreadTenantMessagesRefOnApplication(
    landlordId: string,
    apartmentId: string,
    tenantId: string
  ): Promise<void> {
    const batch = this.firestoreService.getBatch();

    const applicationPath = `${FIRESTORE_COLLECTION_PATH.users.landlordProfiles.apartments.root
      .replace('{uid}', landlordId)
      .replace('{landlordId}', landlordId)}/${apartmentId}/applications/${tenantId}`;
    const applicationDocRef = this.firestoreService.getDocRef(applicationPath, { fetchWithId: true });
    batch.set(applicationDocRef, { unreadTenantMessagesRef: [] }, { merge: true });

    return batch.commit();
  }

  public async sendMessage(application: IApplication, message: TChatMessageCreate): Promise<void> {
    const batch = this.firestoreService.getBatch();
    const apartmentPath = `${FIRESTORE_COLLECTION_PATH.users.landlordProfiles.apartments.root
      .replace('{uid}', application.landlordId)
      .replace('{landlordId}', application.landlordId)}/${application.apartmentId}`;
    const applicationPath = `${apartmentPath}/applications/${application.tenantProfile.uid}`;
    const messageId = uuid4();
    const messagesPath = `${FIRESTORE_COLLECTION_PATH.users.landlordProfiles.apartments.chatMessages
      .replace('{uid}', application.landlordId)
      .replace('{landlordId}', application.landlordId)
      .replace('{apartmentId}', application.apartmentId)
      .replace('{tenantId}', application.tenantProfile.uid)}/${messageId}`;
    const apartmentDocRef = this.firestoreService.getDocRef(apartmentPath, { fetchWithId: true });
    const applicationDocRef = this.firestoreService.getDocRef(applicationPath, { fetchWithId: true });
    const messageDocRef = this.firestoreService.getDocRef(messagesPath, { setCreatedAt: true });

    if (message.senderId === message.landlordId) {
      batch.update(applicationDocRef, {
        isChatDisabled: false,
        unreadLandlordMessagesRef: arrayUnion(messageDocRef.id),
      });
    } else {
      // Add TenantID to unreadTenantChats on apartment
      batch.update(apartmentDocRef, { unreadTenantChatsRef: arrayUnion(application.tenantProfile.uid) });
      // Add TenantID to unreadTenantMessages on application
      batch.update(applicationDocRef, {
        unreadTenantMessagesRef: arrayUnion(messageDocRef.id),
      });
    }
    batch.update(applicationDocRef, {
      lastMessageSenderId: message.senderId,
      lastMessage: message.text,
      updatedAt: new Date(),
      lastMessageSent: new Date(),
    });
    batch.set(messageDocRef, message);
    return batch.commit().catch(console.error);
  }

  public async sendMultiMessageToTenant(
    applications: IApplication[],
    landlordProfile: ILandlordProfile,
    text: string,
    salutation: SALUTATION_TYPES
  ): Promise<void> {
    const batch = this.firestoreService.getBatch();

    for (const application of applications) {
      let textMessage = text;
      if (salutation === SALUTATION_TYPES.POLITE) {
        textMessage = `<p>Sehr geehrte/r Interessent/in, </p>` + text;
      }
      if (salutation === SALUTATION_TYPES.HELLO) {
        textMessage = `<p>Hallo ${application?.tenantProfile?.personalInformation?.firstName}, </p>` + text;
      }
      const message: TChatMessageCreate = {
        attachment: null,
        apartmentId: application.apartmentId,
        landlordId: application.landlordId,
        tenantId: application.tenantProfile.uid,
        senderId: landlordProfile?.uid,
        senderName:
          landlordProfile?.personalInformation?.firstName && landlordProfile?.personalInformation?.lastName
            ? `${landlordProfile?.personalInformation.firstName} ${landlordProfile?.personalInformation.lastName}`
            : 'Vermieter',
        senderAvatar: landlordProfile?.photoUrl,
        text: textMessage,
      };

      const apartmentPath = `${FIRESTORE_COLLECTION_PATH.users.landlordProfiles.apartments.root
        .replace('{uid}', application.landlordId)
        .replace('{landlordId}', application.landlordId)}/${application.apartmentId}`;
      const applicationPath = `${apartmentPath}/applications/${application.tenantProfile.uid}`;
      const messageId = uuid4();
      const messagesPath = `${FIRESTORE_COLLECTION_PATH.users.landlordProfiles.apartments.chatMessages
        .replace('{uid}', application.landlordId)
        .replace('{landlordId}', application.landlordId)
        .replace('{apartmentId}', application.apartmentId)
        .replace('{tenantId}', application.tenantProfile.uid)}/${messageId}`;
      const apartmentDocRef = this.firestoreService.getDocRef(apartmentPath, { fetchWithId: true });
      const applicationDocRef = this.firestoreService.getDocRef(applicationPath, { fetchWithId: true });
      const messageDocRef = this.firestoreService.getDocRef(messagesPath, { setCreatedAt: true });

      if (message.senderId === application.tenantProfile?.uid) {
        batch.update(apartmentDocRef, { unreadTenantChatsRef: arrayUnion(application.tenantProfile.uid) });
        batch.update(applicationDocRef, {
          unreadTenantMessagesRef: arrayUnion(messageDocRef.id),
          lastMessageSent: new Date(),
        });
      } else {
        batch.update(applicationDocRef, {
          isChatDisabled: false,
          unreadLandlordMessagesRef: arrayUnion(messageDocRef.id),
          lastMessageSent: new Date(),
        });
      }

      batch.update(applicationDocRef, {
        lastMessageSenderId: message.senderId,
        lastMessage: message.text,
        lastMessageSent: new Date(),
        updatedAt: new Date(),
      });
      batch.set(messageDocRef, message);
    }

    return batch.commit().catch(console.error);
  }

  public updateIsTypingStatus(application: IApplication, isTyping: IIsTyping): Promise<void> {
    const path = `${FIRESTORE_COLLECTION_PATH.users.landlordProfiles.apartments.applications
      .replace('{uid}', application.landlordId)
      .replace('{landlordId}', application.landlordId)
      .replace('{apartmentId}', application.apartmentId)}/${application.tenantProfile.uid}`;

    return this.firestoreService.setDbDoc({ ...isTyping }, path);
  }
}
