import { FC, useContext, useState } from 'react';
import PageLayout from 'component/layouts/PageLayout';
import { ROUTES } from 'core/const/routes';
import { useTranslation } from 'react-i18next';
import ApplicationListContext from 'core/context/application-list.context';
import { Grid } from '@mui/material';
import { IApplication } from '@wohnsinn/ws-ts-lib';
import { getApartmentCardInformationFromApplication } from 'core/helper/get-apartment-card-information';
import ApartmentDrawer from 'component/organisms/ApartmentDrawer';
import ApartmentCard from 'component/molecules/Cards/ApartmentCard';
import styles from 'component/organisms/Chat/Chat.module.scss';
import CTACard from 'component/molecules/Cards/CTACard';
import LoadingAnimation from 'component/atoms/LoadingAnimation';

const ApplicationsView: FC = () => {
  const { t } = useTranslation('common');
  const { likeList, isLoading } = useContext(ApplicationListContext);
  const [selectedApplication, setSelectedApplication] = useState<IApplication>(null);
  const [showApartmentDrawer, setShowApartmentDrawer] = useState<boolean>(false);
  const { t: r } = useTranslation('routes');

  const handleApplicationSelection = (application: IApplication): void => {
    setSelectedApplication(application);
    setShowApartmentDrawer(true);
  };

  return (
    <PageLayout pageTitle={!likeList.length || isLoading ? null : r(ROUTES.tenantRoutes.applications.title)}>
      <ApartmentDrawer
        isApplicationView={true}
        application={selectedApplication}
        show={showApartmentDrawer}
        close={setShowApartmentDrawer}
        onClose={() => setShowApartmentDrawer(false)}
        apartmentId={selectedApplication?.apartmentId}
      />

      {isLoading ? (
        <LoadingAnimation />
      ) : !likeList.length ? (
        <Grid container justifyContent={'center'}>
          <Grid item xs={12} md={6}>
            <div className={styles.emptyApplicationsWrapper}>
              <CTACard
                title={t('noApplications.title')}
                text={t('noApplications.text')}
                imgSrc={t('pictogram.chatMessage')}
                imgAltText={t('bookmarkIcon')}
              />
            </div>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2}>
          {likeList.map((application, index) => (
            <Grid key={`${application.apartmentId}-${index}`} item xs={12} sm={6} md={4}>
              <ApartmentCard
                application={application}
                isExclusive={false}
                clickHandler={() => handleApplicationSelection(application)}
                apartmentCardInformation={getApartmentCardInformationFromApplication(application)}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </PageLayout>
  );
};

export default ApplicationsView;
