import ApartmentListLoadingView from 'component/molecules/LoadingElements/ApartmentListLoadingView';
import CTACard from 'component/molecules/Cards/CTACard';
import FilterBar from 'component/molecules/FilterBar';
import FilterList from 'component/molecules/FilterList';
import ModalContext from 'core/context/modal.context';
import PageLayout from 'component/layouts/PageLayout';
import TenantFilterParamsContext from 'core/context/tenant-filter-params.context';
import UserContext from 'core/context/user.context';
import styles from './SearchView.module.scss';
import { FC, Fragment, useContext, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { IPaginatedApartmentsListResponse } from '@wohnsinn/ws-ts-lib';
import { InView } from 'react-intersection-observer';
import { InfiniteData, useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { ROUTES } from 'core/const/routes';
import { useTranslation } from 'react-i18next';
import { wohnsinnServices } from 'App';
import { MODAL_IDENTIFIER } from 'core/enum/modals.enum';
import NoMatchesScreen from '../../../component/organisms/NoMatchesScreen';
import ApartmentDrawer from 'component/organisms/ApartmentDrawer';
import { getApartmentCardInformationFromApartment } from 'core/helper/get-apartment-card-information';
import ApartmentCard from 'component/molecules/Cards/ApartmentCard';

const SearchView: FC = () => {
  const { user } = useContext(UserContext);
  const { tenantFilterParams } = useContext(TenantFilterParamsContext);
  const queryClient = useQueryClient();
  const { t: r } = useTranslation('routes');
  const { t } = useTranslation('common');
  const { t: l } = useTranslation('common', { keyPrefix: 'view.ApartmentListView' });
  const [random, setRandom] = useState(0);
  const [filterMenuOpen, setFilterMenuOpen] = useState<boolean>(false);
  const { openModal } = useContext(ModalContext);
  const [showApartmentDrawer, setShowApartmentDrawer] = useState<boolean>(false);
  const [apartmentId, setApartmentId] = useState<string>('');

  const fetchApartments = async (pageParam: number = null, tenantFilterParams: any) => {
    return (await wohnsinnServices.apartmentService.getPerfectMatchList(
      tenantFilterParams,
      pageParam
    )) as IPaginatedApartmentsListResponse;
  };

  const handleApartmentDrawer = (apartmentId: string) => {
    setApartmentId(apartmentId);
    setShowApartmentDrawer(true);
  };

  const createResponse = (data: InfiniteData<IPaginatedApartmentsListResponse>) => {
    try {
      const apartments = data.pages.flatMap((page) => page.apartments) || [];
      let allMatchesRated = false;

      if (data?.pages?.length) {
        allMatchesRated = data.pages[data.pages.length - 1].allMatchesRated;
      }
      return { apartments, allMatchesRated };
    } catch (e) {
      console.error('error creating response');
    }
  };

  const handleApartmentDrawerClose = (closeDrawer: () => void, newData?: any) => {
    if (newData) {
      queryClient.setQueryData(['matches', tenantFilterParams, Math.random()], { ...newData });
    }

    // TODO: this is a workout to force rerender, to update apartment list to remove rated apartment from list
    setRandom(random + 1);
    closeDrawer();
  };

  const { data, isFetching, isLoading, fetchNextPage } = useInfiniteQuery({
    queryKey: ['matches', tenantFilterParams],
    initialPageParam: 0,
    queryFn: ({ pageParam }) => fetchApartments(pageParam, tenantFilterParams),
    getNextPageParam: ({ pageCount, page }) => (pageCount > page ? page : undefined),
    select: (data) => createResponse(data),
  });

  useEffect(() => {
    if (isFetching && isLoading) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [isLoading]);

  const renderNotificationCard = (t: any, l: any, r: any, fullWidth?: boolean) => {
    return (
      <Grid className={styles.cardParent} item xs={12} sm={fullWidth ? 12 : 6} md={fullWidth ? 12 : 4}>
        <div className={styles.ctaCardWrapper}>
          <CTACard
            imgSrc={t('pictogram.alarm')}
            alternativeStyle
            title={l('notification.title')}
            text={l('notification.text')}
            ctaText={l('notification.linkText')}
            onClick={() =>
              openModal({
                id: MODAL_IDENTIFIER.SIGN_UP_USER,
                data: {
                  title: 'Erstelle ein Konto, um deine Suche zu speichern',
                  isProbablyTenant: true,
                },
              })
            }
          />
        </div>
      </Grid>
    );
  };

  return (
    <PageLayout showPageTitle={false} pageTitle={r(ROUTES.staticRoutes.search.title)}>
      <ApartmentDrawer
        isApplicationView={false}
        show={showApartmentDrawer}
        close={setShowApartmentDrawer}
        onClose={handleApartmentDrawerClose}
        apartmentId={apartmentId}
      />
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <FilterBar filterMenuOpen={filterMenuOpen} setFilterMenuOpen={setFilterMenuOpen} />

          <div className={styles.moreFilters}>
            <FilterList />
          </div>

          {isFetching && isLoading ? (
            <ApartmentListLoadingView />
          ) : (
            <>
              {data?.allMatchesRated && (
                <div className={styles.ctaCardWrapper}>
                  <CTACard
                    imgSrc={l('allMatchesRated.image')}
                    imgAltText={l('allMatchesRated.imageAlt')}
                    title={l('allMatchesRated.title')}
                    ctaText={l('allMatchesRated.linkText')}
                    link={r(ROUTES.tenantRoutes.chat.path)}
                  />
                </div>
              )}
              {/*APARTMENTS GRID*/}
              {data?.apartments ? (
                data?.apartments.length ? (
                  <Grid spacing={2} container>
                    {data?.apartments.map((apartment, index) => (
                      <Fragment key={`${apartment.id}-${index}`}>
                        {!user && index === 1 ? renderNotificationCard(t, l, r) : null}

                        {!user && data?.apartments.length > 11 && index === 9 ? (
                          <Grid item xs={12} sm={6} md={4}>
                            <div className={styles.ctaCardWrapper}>
                              <CTACard
                                alternativeStyle
                                imgSrc={t('pictogram.alarm')}
                                title={l('searchProfile.title')}
                                text={l('searchProfile.text')}
                                ctaText={l('searchProfile.linkText')}
                                onClick={() => openModal({ id: MODAL_IDENTIFIER.SELECT_USER_TYPE })}
                              />
                            </div>
                          </Grid>
                        ) : null}
                        <Grid item xs={12} sm={6} md={4}>
                          <ApartmentCard
                            isExclusive={apartment.isExclusive}
                            apartmentCardInformation={getApartmentCardInformationFromApartment(apartment)}
                            clickHandler={() => handleApartmentDrawer(apartment.id)}
                          />
                        </Grid>
                      </Fragment>
                    ))}
                    {!user && data?.apartments.length > 11 ? renderNotificationCard(t, l, r) : null}
                  </Grid>
                ) : (
                  !data?.allMatchesRated && <NoMatchesScreen />
                )
              ) : null}
            </>
          )}
          {isFetching && <ApartmentListLoadingView />}
          <InView as="div" onChange={(inView) => !isFetching && inView && fetchNextPage()}>
            {({ ref }) => <span ref={ref}></span>}
          </InView>
        </div>
      </div>
    </PageLayout>
  );
};

export default SearchView;
