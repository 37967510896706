import { onSnapshot, WhereFilterOp } from 'firebase/firestore';
import { FC, useContext, useEffect, useState } from 'react';
import UserContext from 'core/context/user.context';
import { wohnsinnServices } from 'App';
import styles from './PremiumSubscriptionDrawer.module.scss';
import RoundIconButton from 'component/atoms/Buttons/RoundIconButton';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import Headline, { HEADLINE_SIZE } from 'component/atoms/typographie/Headline';
import CheckList from 'component/atoms/CheckList';
import PricingOptions from 'component/atoms/PricingOptions';
import CTAButton, { getSubmitIcon } from 'component/atoms/Buttons/CTAButton';
import { SUBMIT_BUTTON_MODE } from 'core/enum/submit-button-mode.enum';

export interface PriceOption {
  interval: string;
  interval_count: number;
  popular?: boolean;
  savings?: string;
  metadata: any;
  id: string;
  unit_amount: number;
}

const PremiumSubscriptionDrawer: FC<{ closeAction: () => void }> = ({ closeAction }) => {
  const { user } = useContext(UserContext);
  const { firestoreService } = wohnsinnServices;
  const [prices, setPrices] = useState<any[]>([]);
  const [selectedOption, setSelectedOption] = useState<PriceOption>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { mixpanelTrackingService } = wohnsinnServices;

  useEffect(() => {
    const fetchPrices = async () => {
      try {
        if (!process.env.REACT_APP_WOHNSINN_PREMIUM_ID) {
          throw new Error('REACT_APP_WOHNSINN_PREMIUM_ID is not defined');
        }

        const whereFields = [{ fieldPath: 'active', opStr: '==' as WhereFilterOp, value: true }];
        const colSnap = await firestoreService.getCollectionSnapWithWhere<PriceOption>(
          `products/${process.env.REACT_APP_WOHNSINN_PREMIUM_ID}/prices`,
          whereFields
        );

        let prices = colSnap.docs.map((doc) => {
          return { ...doc.data(), id: doc.id };
        });
        prices = prices.sort((a, b) => a?.metadata?.position - b?.metadata?.position);
        setPrices(prices);
        const defaultOption = prices.find((price) => price.metadata?.default === 'true');
        setSelectedOption(defaultOption);
      } catch (error) {
        console.error('Error fetching prices:', error);
      }
    };

    fetchPrices();
  }, []);

  const handleCheckOut = async (priceId: string) => {
    try {
      setIsLoading(true);
      if (!user?.uid) {
        throw new Error('User not logged in');
      }
      mixpanelTrackingService.trackPricing(priceId, user?.uid);

      const path = `users/${user.uid}/checkout_sessions`;
      const docRef = await firestoreService.addDbDoc(
        {
          price: priceId,
          success_url: window?.location?.href,
          mode: 'subscription',
          cancel_url: window?.location?.href,
          automatic_tax: { enabled: true },
        },
        path
      );
      onSnapshot(docRef, (snapshot) => {
        // @ts-ignore
        const { error, url } = snapshot.data();
        if (error) {
          console.error(error);
          setIsLoading(false);
        }
        if (url) {
          setIsLoading(false);
          window.location.assign(url);
        }
      });
    } catch (error) {
      console.error('Error during checkout:', error);
    }
  };

  return (
    <>
      <div className={styles.backButton}>
        {closeAction ? <RoundIconButton onClick={closeAction} buttonText={'close'} icon={faTimes} /> : null}
      </div>
      <div className={styles.wrapper}>
        <div className={styles.logo}>
          <img src="/assets/icon/wohnsinn-logo-pro.svg" alt="Wohnsinn Logo" />
        </div>
        <Headline tag={'span'} size={HEADLINE_SIZE.H2}>
          {'Diese Anzeige ist exklusiv für Wohnsinn Pro - Mitglieder'}
        </Headline>{' '}
        <div className={styles.checkList}>
          <CheckList accentColor items={PRO_SUBSCRIPTION} />
        </div>
        <PricingOptions priceList={prices} setSelectedOption={setSelectedOption} selectedOption={selectedOption} />
        <div className={styles.submitButton}>
          <CTAButton
            spinIcon={isLoading}
            buttonText={`Weiter - ${(selectedOption?.unit_amount / 100).toFixed(2)} € insgesamt`}
            rounded={false}
            icon={isLoading ? getSubmitIcon(SUBMIT_BUTTON_MODE.SUBMITTING) : undefined}
            disabled={isLoading}
            iconPosition={isLoading ? 'end' : undefined}
            expand={'full'}
            onClick={() => handleCheckOut(selectedOption?.id)}
          />
        </div>
      </div>
    </>
  );
};

export default PremiumSubscriptionDrawer;
const PRO_SUBSCRIPTION = [
  {
    text: 'Bessere Chancen',
    bold: false,
    invert: true,
  },
  {
    text: 'Weniger Mitbewerber',
    bold: false,
    invert: true,
  },
  {
    text: 'Exklusive Wohnungen',
    bold: false,
    invert: true,
  },
];
