import styles from './ApartmentCardMedia.module.scss';
import ImageGallery from '../../../ImageGallery';
import { FC, useState } from 'react';
import { IMedia } from '@wohnsinn/ws-ts-lib';
import { useTranslation } from 'react-i18next';

const ApartmentCardMedia: FC<{
  media: IMedia[];
  apartmentId: string;
  isIntersecting?: boolean;
  onGalleryClick?: any;
  showBouncingHeart?: boolean;
}> = ({ media, apartmentId, isIntersecting, onGalleryClick, showBouncingHeart }) => {
  const { t } = useTranslation('common');
  const [slideShowIndex, setSlideShowIndex] = useState(0);
  return (
    <div className={`${styles.image} ${showBouncingHeart ? styles.showBouncingHeart : ''}`}>
      {media && media.length && isIntersecting ? (
        <ImageGallery
          id={apartmentId}
          noLightBox
          setSlideShowIndex={setSlideShowIndex}
          slideShowIndex={slideShowIndex}
          media={media}
          onClick={onGalleryClick}
        />
      ) : (
        <button onClick={onGalleryClick}>
          <img
            loading="lazy"
            className={styles.placeholder}
            alt={'placeholder-apartment-image'}
            src={t('apartmentPlaceholder')}
          />
        </button>
      )}
    </div>
  );
};

export default ApartmentCardMedia;
