import { Accept, useDropzone } from 'react-dropzone';
import { FC, useContext, useEffect, useState } from 'react';
import SnackBarContext from 'core/context/snackbar.context';
import { IMedia, IUpload, MEDIA_TYPE } from '@wohnsinn/ws-ts-lib';
import uuid4 from 'uuid4';
import { UPLOAD_TYPE } from 'component/molecules/DocumentUploadDropZone';
import { getDownloadURL } from 'firebase/storage';
import { wohnsinnServices } from 'App';
import UserContext from 'core/context/user.context';
import styles from 'component/molecules/MediaUploadDropZone/MediaUploadDropZone.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowUp, faSpinner } from '@fortawesome/pro-light-svg-icons';
import Text, { TEXT_COLOR, TEXT_TYPE, TEXT_WEIGHT } from 'component/atoms/typographie/Text';
import { useTranslation } from 'react-i18next';

export const ACCEPTED_IMAGE_FILE_TYPES: Accept = {
  'image/jpg': [],
  'image/png': [],
};
export const ACCEPTED_VIDEO_FILE_TYPES: Accept = {
  'video/mp4': [],
  'video/quicktime': [],
};
export const ACCEPTED_DOCUMENT_FILE_TYPES: Accept = {
  'application/pdf': [],
  'image/jpg': [],
  'image/jpeg': [],
  'image/png': [],
};

const MediaUpload: FC<{
  onUpdate: (newFiles: IMedia[]) => void;
  creatorId: string;
  subDirectoryPath?: string;
  allowMultipleFiles?: boolean;
  mediaType: MEDIA_TYPE;
  title?: string;
}> = ({ onUpdate, creatorId, subDirectoryPath, allowMultipleFiles, mediaType, title = 'mediaUploadTitle' }) => {
  const { t } = useTranslation('common');
  const { handleSnackBar } = useContext(SnackBarContext);
  const { user } = useContext(UserContext);
  const { firebaseStorageService } = wohnsinnServices;
  const [uploadedFiles, setUploadedFiles] = useState<IMedia[]>([]);
  const [isUploading, setIsUploading] = useState(false);

  const uploadFile = async (file: File) => {
    const id = uuid4();
    const upload = await firebaseStorageService.createFileUpload(file, {
      uploadType: UPLOAD_TYPE.APARTMENT_DOCUMENT,
      id,
      creatorId,
      mediaType,
      subDirectoryPath,
    });

    // TODO : is this needed?
    await upload.uploadTask;

    const fileUploadPath = upload.uploadPath;
    const downloadURL = await getDownloadURL(firebaseStorageService.getRef(fileUploadPath));
    const uploadFile: IUpload = { ...upload, id, url: downloadURL };
    return uploadFile;
  };

  const getAcceptedFileTypes = (): Accept => {
    switch (mediaType) {
      case MEDIA_TYPE.DOCUMENT:
        return ACCEPTED_DOCUMENT_FILE_TYPES;
      case MEDIA_TYPE.VIDEO:
        return ACCEPTED_VIDEO_FILE_TYPES;
      case MEDIA_TYPE.IMAGE:
        return ACCEPTED_IMAGE_FILE_TYPES;
    }
  };

  useEffect(() => {
    if (!isUploading && uploadedFiles.length) {
      onUpdate(uploadedFiles);
      setUploadedFiles([]);
    }
  }, [uploadedFiles, isUploading]);

  const onDropHandler = async (acceptedFiles: any, fileRejections: any) => {
    if (fileRejections.length) {
      handleSnackBar('toast.onlyAllowImages', 'error');
    } else {
      for (const file of acceptedFiles) {
        try {
          setIsUploading(true);
          const upload = await uploadFile(file);
          const newFile: IMedia = {
            alt: upload?.alt,
            updatedAt: new Date(),
            creatorId: user.uid,
            id: upload.id,
            url: upload.url,
            mediaType,
          };
          setUploadedFiles((oldFiles) => [...oldFiles, newFile]);
          setIsUploading(false);
        } catch (error) {
          console.error('Error onDropHandler:', error);
        }
      }
      setIsUploading(false);
    }
  };

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop: (acceptedFiles, fileRejections) => onDropHandler(acceptedFiles, fileRejections),
    multiple: allowMultipleFiles,
    accept: getAcceptedFileTypes(),
  });

  return (
    <div>
      {isUploading ? (
        <FontAwesomeIcon spin={true} width={'23px'} height={'23px'} icon={faSpinner} size={'1x'} />
      ) : (
        <div
          {...getRootProps()}
          id="eindeutig"
          className={`${styles.wrapper} ${isDragActive ? styles.activeDrag : ''}`}
        >
          <input {...getInputProps()} />
          <button className={styles.dropZone} onClick={open} type={'button'}>
            <span className="sr-only">{t(title)}</span>

            <div className={styles.uploadIconWrapper}>
              <FontAwesomeIcon icon={faCloudArrowUp} size={'2x'} />
            </div>

            <div className={styles.textWrapper}>
              <Text align={'center'} weight={TEXT_WEIGHT.TEXT_WEIGHT_SEMI_BOLD} color={TEXT_COLOR.TEXT_COLOR_PRIMARY}>
                {t(title)}
              </Text>
              <Text align={'center'} type={TEXT_TYPE.TEXT_TYPE_CAPTION}>
                {t(`allowedFileTypes.${mediaType}`)}
              </Text>
            </div>
          </button>
        </div>
      )}
    </div>
  );
};

export default MediaUpload;
