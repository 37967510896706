import { Checkbox, TableBody, TableCell, TableRow } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../../../core/const/routes';
import styles from '../../ApartmentListView.module.scss';
import { TEXT_WEIGHT } from '../../../../../../component/atoms/typographie/Text';
import ContextMenu from '../../../../../../component/atoms/ContextMenu';
import { useTranslation } from 'react-i18next';
import { Dispatch, FC, SetStateAction, useContext } from 'react';
import UserContext from '../../../../../../core/context/user.context';
import { APARTMENT_TYPE, APARTMENT_TYPE_OF_MARKETING, IApartment } from '@wohnsinn/ws-ts-lib';
import CTAButton from '../../../../../../component/atoms/Buttons/CTAButton';
import Badge, { BADGE_COLORS } from '../../../../../../component/atoms/Badge';
import TableCellLink from 'component/atoms/TableCellLink';

const ApartmentListTableBody: FC<{
  apartments: IApartment[];
  setSelectedApartments: Dispatch<SetStateAction<IApartment[]>>;
  selectedApartments: IApartment[];
}> = ({ apartments, selectedApartments, setSelectedApartments }) => {
  const { t: r } = useTranslation('routes');
  const { t } = useTranslation('common');
  const { t: i } = useTranslation('common', { keyPrefix: 'enum' });
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  const handleSelectApartment = (selectedApartment: IApartment) => {
    let foundIndex;
    const found = selectedApartments.find((apartment: IApartment, index) => {
      if (apartment.id === selectedApartment.id) {
        foundIndex = index;
        return apartment.id === selectedApartment.id;
      }
    });

    if (found) {
      const apartments = selectedApartments;
      apartments.splice(foundIndex, 1);
      setSelectedApartments([...apartments]);
    } else {
      setSelectedApartments([...selectedApartments, selectedApartment]);
    }
  };

  const getApartmentFloor = (apartment: IApartment): string => {
    const type = apartment?.mainInformation?.address?.apartmentType;
    const floor = apartment?.mainInformation?.address?.floor;

    if (parseInt(floor as unknown as string) === 0) return 'EG'; // Handle ground floor specifically

    switch (type) {
      case APARTMENT_TYPE.BASEMENT_APARTMENT:
      case APARTMENT_TYPE.BASEMENT:
        return t('basementShortCut');
      case APARTMENT_TYPE.GROUND:
      case APARTMENT_TYPE.GROUND_FLOOR:
        return t('groundFloorShortCut');
      case APARTMENT_TYPE.ROOF:
      case APARTMENT_TYPE.ATTIC_APARTMENT:
      case APARTMENT_TYPE.PENTHOUSE:
        return t('roofShortCut');
      case APARTMENT_TYPE.HOUSE:
        return '-';
      default:
        return floor ? `${floor}.OG` : '-';
    }
  };

  return (
    <>
      <TableBody>
        {apartments.map((apartment, index) => (
          <TableRow key={apartment.id + index} hover={true}>
            {user?.isAdmin ? (
              <TableCell width={70}>
                {
                  <Checkbox
                    id={`checkbox-${apartment.id}`}
                    onChange={() => handleSelectApartment(apartment)}
                    checked={selectedApartments.some((selectedApartment) => selectedApartment.id === apartment.id)}
                  />
                }
              </TableCell>
            ) : null}
            <TableCell width={70} className={styles.hover}>
              <Link to={`${r(ROUTES.landlordRoutes.apartment.overview.path)}/`.replace(':apartmentId', apartment.id)}>
                <div
                  className={styles.imageContainer}
                  style={{
                    backgroundImage: `url(${
                      apartment?.media[0]?.url ? apartment?.media[0]?.url : 'assets/images/apartment-placeholder.webp'
                    })`,
                  }}
                ></div>
              </Link>
            </TableCell>
            <TableCellLink
              showToolTip={true}
              info={apartment?.mainInformation?.address?.street}
              toLink={r(ROUTES.landlordRoutes.apartment.overview.path).replace(':apartmentId', apartment.id)}
            />
            <TableCellLink
              info={apartment?.mainInformation?.address?.houseNumber}
              toLink={r(ROUTES.landlordRoutes.apartment.overview.path).replace(':apartmentId', apartment.id)}
            />
            <TableCellLink
              info={apartment?.mainInformation?.address?.postalCode}
              toLink={r(ROUTES.landlordRoutes.apartment.overview.path).replace(':apartmentId', apartment.id)}
            />
            <TableCellLink
              noWrap
              info={apartment?.mainInformation?.address?.city}
              toLink={r(ROUTES.landlordRoutes.apartment.overview.path).replace(':apartmentId', apartment.id)}
            />
            {user?.isAdmin && (
              <TableCellLink
                onClick={() => navigator.clipboard.writeText(apartment?.creatorId)}
                info={`${apartment?.contactPerson?.firstName} ${apartment?.contactPerson?.name}`}
                infoSecondLine={`${apartment?.creatorId}`}
                toLink={r(ROUTES.landlordRoutes.apartment.overview.path).replace(':apartmentId', apartment.id)}
              />
            )}
            {user?.isAdmin && (
              <TableCellLink
                info={
                  apartment?.publishedAt
                    ? `${new Date(apartment?.publishedAt).toLocaleDateString()} - ${new Date(
                        apartment?.publishedAt
                      ).toLocaleTimeString()}`
                    : '-'
                }
              />
            )}
            <TableCell>
              {apartment?.applicationRefList?.length ? (
                <CTAButton
                  notificationBadge={!!apartment.unreadTenantChatsRef.length}
                  onClick={() =>
                    navigate(r(ROUTES.landlordRoutes.apartment.applications.path).replace(':apartmentId', apartment.id))
                  }
                  size={'small'}
                  buttonText={`${apartment?.applicationRefList?.length} Bewerbung${
                    apartment?.applicationRefList?.length > 1 ? 'en' : ''
                  }`}
                />
              ) : (
                '-'
              )}
            </TableCell>
            <TableCellLink
              showInfo={false}
              toLink={r(ROUTES.landlordRoutes.apartment.overview.path).replace(':apartmentId', apartment.id)}
            >
              <Badge
                weight={TEXT_WEIGHT.TEXT_WEIGHT_LIGHT}
                color={apartment.isPublished ? BADGE_COLORS.SUCCESS : BADGE_COLORS.DISABLED}
                text={apartment.isPublished ? 'Veröffentlicht' : 'Deaktiviert'}
              />
            </TableCellLink>
            {process.env.REACT_APP_SHOW_SALE === 'true' && (
              <TableCellLink
                info={
                  apartment?.mainInformation?.typeOfMarketing
                    ? i(`typeOfMarketing.${apartment?.mainInformation?.typeOfMarketing}`)
                    : '-'
                }
                toLink={r(ROUTES.landlordRoutes.apartment.overview.path).replace(':apartmentId', apartment.id)}
              />
            )}

            <TableCellLink
              info={
                apartment?.mainInformation?.typeOfImmo ? i(`immoType.${apartment?.mainInformation?.typeOfImmo}`) : '-'
              }
              toLink={r(ROUTES.landlordRoutes.apartment.overview.path).replace(':apartmentId', apartment.id)}
            />

            <TableCellLink
              info={getApartmentFloor(apartment)}
              toLink={r(ROUTES.landlordRoutes.apartment.overview.path).replace(':apartmentId', apartment.id)}
            />
            <TableCellLink
              info={
                apartment?.mainInformation?.address?.locationInBuilding
                  ? `${apartment?.mainInformation?.address?.locationInBuilding}`
                  : '-'
              }
              toLink={r(ROUTES.landlordRoutes.apartment.overview.path).replace(':apartmentId', apartment.id)}
            />
            <TableCellLink
              info={apartment?.areas?.livingArea ? `${apartment?.areas?.livingArea} m²` : '-'}
              toLink={r(ROUTES.landlordRoutes.apartment.overview.path).replace(':apartmentId', apartment.id)}
            />
            <TableCellLink
              info={
                apartment?.mainInformation?.typeOfMarketing === APARTMENT_TYPE_OF_MARKETING.RENT
                  ? apartment?.cost?.warmRent
                    ? `${apartment?.cost?.warmRent}€`
                    : apartment?.cost?.coldRent
                    ? `${apartment?.cost?.coldRent}€`
                    : '-'
                  : apartment?.cost?.purchasePrice?.value
                  ? `${apartment?.cost?.purchasePrice?.value}€`
                  : '-'
              }
              toLink={r(ROUTES.landlordRoutes.apartment.overview.path).replace(':apartmentId', apartment.id)}
            />
            <TableCellLink
              info={apartment?.areas?.numberOfRooms ?? '-'}
              toLink={r(ROUTES.landlordRoutes.apartment.overview.path).replace(':apartmentId', apartment.id)}
            />
            <TableCell id={apartment.id} align={'left'} className={styles.stickyCell}>
              <ContextMenu apartment={apartment} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </>
  );
};
export default ApartmentListTableBody;
