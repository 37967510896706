import { ROUTES } from './routes';
const isWogeEnvironment = process.env.REACT_APP_ENVIRONMENT === 'woge';

export interface IMenuItemProps {
  badge?: string;
  label?: string;
  link: string;
  notifications?: number | boolean;
  openBlank?: boolean;
  shouldOpenModal?: boolean;
  title: string;
}

export interface IMenuNotifications {
  tenantChat: number;
  landlordChat: number;
  account: boolean;
}

export const MENU_ITEMS_SECONDARY = [
  ROUTES.landingPageRoutes.agb,
  ROUTES.landingPageRoutes.about,
  ROUTES.landingPageRoutes.imprint,
  !isWogeEnvironment && ROUTES.landingPageRoutes.prices,
  ROUTES.landingPageRoutes.press,
  ROUTES.landingPageRoutes.contact,
  ROUTES.landingPageRoutes.privacy,
  ROUTES.landingPageRoutes.cooperative,
  ROUTES.landingPageRoutes.hug,
];

export const MENU_SOCIAL_MEDIA = [
  ROUTES.socialMedia.facebook,
  ROUTES.socialMedia.instagram,
  ROUTES.socialMedia.linkedin,
  ROUTES.socialMedia.twitter,
];

export const APARTMENTS_MENU_ITEM: IMenuItemProps = {
  label: ROUTES.landlordRoutes.apartment.list.label,
  link: ROUTES.landlordRoutes.apartment.list.path,
  title: ROUTES.landlordRoutes.apartment.list.title,
  shouldOpenModal: true,
};

export const ADMIN_MENU_ITEM: IMenuItemProps = {
  label: ROUTES.adminRoutes.overview.label,
  link: ROUTES.adminRoutes.overview.path,
  title: ROUTES.adminRoutes.overview.title,
};

export const TENANT_MESSAGES_MENU_ITEM: IMenuItemProps = {
  label: ROUTES.tenantRoutes.chat.label,
  link: ROUTES.tenantRoutes.chat.path,
  title: ROUTES.tenantRoutes.chat.title,
  notifications: 0,
  shouldOpenModal: true,
};

export const TENANT_APPLICATIONS_MENU_ITEM: IMenuItemProps = {
  label: ROUTES.tenantRoutes.applications.title,
  link: ROUTES.tenantRoutes.applications.path,
  title: ROUTES.tenantRoutes.applications.title,
  notifications: 0,
  shouldOpenModal: true,
};

export const SEARCH_MENU_ITEM: IMenuItemProps = {
  title: ROUTES.staticRoutes.search.title,
  label: ROUTES.staticRoutes.search.label,
  link: ROUTES.staticRoutes.search.path,
  notifications: 0,
};

export const ABOUT_WS_ITEM: IMenuItemProps = {
  link: ROUTES.landingPageRoutes.about.path,
  title: ROUTES.landingPageRoutes.about.title,
  label: ROUTES.landingPageRoutes.about.label,
  notifications: 0,
};

export const PRICES_ITEM: IMenuItemProps = {
  link: ROUTES.landingPageRoutes.prices.path,
  title: ROUTES.landingPageRoutes.prices.title,
  label: ROUTES.landingPageRoutes.prices.label,
  notifications: 0,
};

export const ACCOUNT_MENU_ITEM: IMenuItemProps = {
  link: ROUTES.tenantRoutes.account.overview.path,
  title: ROUTES.tenantRoutes.account.overview.title,
  label: ROUTES.tenantRoutes.account.overview.label,
  notifications: 0,
  shouldOpenModal: true,
};

export const SETTINGS_MENU_ITEM: IMenuItemProps = {
  link: ROUTES.userRoutes.settings.path,
  title: ROUTES.userRoutes.settings.title,
  label: ROUTES.userRoutes.settings.label,
  notifications: 0,
  shouldOpenModal: true,
};

export const ADVERTISE_NOW_MENU_ITEM: IMenuItemProps = {
  link: ROUTES.landingPageRoutes.landlord.path,
  title: ROUTES.landingPageRoutes.landlord.title,
  label: ROUTES.landingPageRoutes.landlord.label,
  notifications: 0,
  badge: 'Kostenlos inserieren!',
  shouldOpenModal: false,
  openBlank: true,
};

export const LANDLORD_ACCOUNT_MENU_ITEM: IMenuItemProps = {
  link: ROUTES.landlordRoutes.account.path,
  title: ROUTES.landlordRoutes.account.title,
  label: ROUTES.landlordRoutes.account.label,
  notifications: 0,
  shouldOpenModal: true,
};
