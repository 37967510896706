import ModalWrapper from 'component/modals/ModalWrapper';
import { Grid } from '@mui/material';
import { FormContext } from 'core/context/form.context';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import CTAButton, { getSubmitIcon } from 'component/atoms/Buttons/CTAButton';
import Spacer, { SPACER_SIZE } from 'component/atoms/Spacer';
import { useContext, useState } from 'react';
import SnackBarContext from 'core/context/snackbar.context';
import ModalContext from 'core/context/modal.context';
import { SUBMIT_BUTTON_MODE } from 'core/enum/submit-button-mode.enum';
import { wohnsinnServices } from 'App';
import UserContext from 'core/context/user.context';
import MultiMessageFormComponents from 'component/molecules/MultiMessageFormComponents';
import { useTranslation } from 'react-i18next';

export enum SALUTATION_TYPES {
  NONE = 'NONE',
  HELLO = 'HELLO',
  POLITE = 'POLITE',
}

export const MULTI_MESSAGE_SCHEMA = {
  message: Joi.string().required().min(5),
  salutation: Joi.valid(...Object.keys(SALUTATION_TYPES)).required(),
};

interface IMultiMessage {
  salutation: SALUTATION_TYPES;
  message: string;
}

const RequestSaleModal = () => {
  const [submitButtonMode, setSubmitButtonMode] = useState<SUBMIT_BUTTON_MODE>(SUBMIT_BUTTON_MODE.NONE);
  const { handleSnackBar } = useContext(SnackBarContext);
  const { closeModal, modalData } = useContext(ModalContext);
  const { t } = useTranslation('common');

  const { chatService } = wohnsinnServices;
  const { landlordProfile } = useContext(UserContext);
  const { control, watch, setValue, handleSubmit } = useForm<IMultiMessage>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    resolver: joiResolver(Joi.object(MULTI_MESSAGE_SCHEMA)),
  });
  const message = watch('message');
  const salutation = watch('salutation');

  const onSuccess = async () => {
    setSubmitButtonMode(SUBMIT_BUTTON_MODE.SUBMITTING);
    try {
      await chatService.sendMultiMessageToTenant(modalData?.applicationList, landlordProfile, message, salutation);

      setSubmitButtonMode(SUBMIT_BUTTON_MODE.SUCCESS);

      handleSnackBar('toast.messageSend', 'success');
      closeModal();
    } catch (e) {
      setSubmitButtonMode(SUBMIT_BUTTON_MODE.ERROR);

      handleSnackBar('toast.error.unknown', 'error');
      console.error('Error on onSuccess in RequestSalesModal', e);
    }
  };

  return (
    <ModalWrapper
      title={t('multiMessage.title')}
      subtitle={t('multiMessage.subtitle', { count: modalData?.applicationList?.length })}
    >
      <FormContext.Provider value={{ control, setValue, watch }}>
        <form onSubmit={handleSubmit(onSuccess, (e) => console.error('Error on RequestSalesModal', e))}>
          <Grid item xs={12}>
            <MultiMessageFormComponents />
          </Grid>
          <Spacer size={SPACER_SIZE.SM} />
          <CTAButton
            icon={getSubmitIcon(submitButtonMode)}
            spinIcon={submitButtonMode === SUBMIT_BUTTON_MODE.SUBMITTING}
            expand={'block'}
            buttonText={t('send')}
            rounded={false}
            type={'submit'}
          />
        </form>
      </FormContext.Provider>
    </ModalWrapper>
  );
};

export default RequestSaleModal;
