enum LOCAL_STORAGE_KEYS {
  CURRENT_MODAL = 'CURRENT_MODAL',
  EMAIL = 'EMAIL',
  REDIRECT_URL = 'REDIRECT_URL',
  USER_HAS_SEEN_HOW_TO_SLIDES = 'USER_HAS_SEEN_HOW_TO_SLIDES',
  USER_TYPE = 'USER_TYPE',
  APARTMENT_ID = 'APARTMENT_ID',
  TENANT_HOUSEHOLD = 'TENANT_HOUSEHOLD',
}

export default LOCAL_STORAGE_KEYS;
