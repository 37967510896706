import { FC, useContext, useEffect, useState } from 'react';
import PageLayout from './../../../../component/layouts/PageLayout';
import { ROUTES } from '../../../../core/const/routes';
import { useTranslation } from 'react-i18next';
import { IApartment, IOrganization, MATCHING_MODE } from '@wohnsinn/ws-ts-lib';
import PageTitle from './../../../../component/molecules/PageTitle';
import styles from './OrganizationPortfolioView.module.scss';
import ApartmentLoadingCard from './../../../../component/molecules/LoadingElements/ApartmentLoadingCard';
import UserContext from './../../../../core/context/user.context';
import ApplicationListContext from './../../../../core/context/application-list.context';
import { wohnsinnServices } from '../../../../App';
import { Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import Headline, { HEADLINE_SIZE } from '../../../../component/atoms/typographie/Headline';
import { getApartmentCardInformationFromApartment } from 'core/helper/get-apartment-card-information';
import ApartmentDrawer from 'component/organisms/ApartmentDrawer';
import ApartmentCard from 'component/molecules/Cards/ApartmentCard';

export interface IApartmentWithRating extends IApartment {
  rating: MATCHING_MODE;
}

const OrganizationPortfolioView: FC = () => {
  const [showApartment, setShowApartment] = useState<boolean>(false);
  const [apartmentId, setApartmentId] = useState<string>('');
  const params = useParams<{ slug: string }>();
  const { t: r } = useTranslation('routes');
  const { user } = useContext(UserContext);
  const { apartmentService, organizationService } = wohnsinnServices;
  const { nopeList, maybeList } = useContext(ApplicationListContext);
  const [organization, setOrganization] = useState<IOrganization>(null);
  const [isLoadingContent, setIsLoadingContent] = useState(true);
  const [apartmentList, setApartmentList] = useState<IApartment[]>([]);
  const [maybeApartmentIdList, setMaybeApartmentIdList] = useState<string[]>([]);
  const [nopeApartmentIdList, setNopeApartmentIdList] = useState<string[]>([]);
  const { t: v } = useTranslation('common', { keyPrefix: 'view.ApartmentListView' });
  const navigate = useNavigate();

  const fetchData = async (fetchWithLoadingAnimation = false) => {
    const organization: IOrganization = await organizationService.getOrganizationBySlug(params?.slug);

    if (organization) {
      setOrganization(organization);

      if (fetchWithLoadingAnimation) {
        setIsLoadingContent(true);
      }

      const apartmentDocSnapShots = await apartmentService.getOrganizationApartmentDocSnapShots(
        organization?.id ?? null
      );

      if (maybeList?.length) {
        setMaybeApartmentIdList(maybeList.map((apt) => apt.apartmentId));
      }
      if (nopeList?.length) {
        setNopeApartmentIdList(nopeList.map((apt) => apt.apartmentId));
      }

      const fetchedApartments: IApartment[] = apartmentDocSnapShots.docs.map((apt) => apt.data());
      setApartmentList(fetchedApartments);

      setIsLoadingContent(false);
    } else {
      return navigate('/');
    }
  };

  useEffect(() => {
    fetchData(true);
  }, []);

  useEffect(() => {
    const newApartmentList = apartmentList.map((apt) => {
      if (user) {
        if (apt?.applicationRefList?.includes(user?.uid)) {
          return { ...apt, rating: MATCHING_MODE.LIKE };
        }

        if (maybeApartmentIdList.includes(apt.id)) {
          return { ...apt, rating: MATCHING_MODE.MAYBE };
        }

        if (nopeApartmentIdList.includes(apt.id)) {
          return { ...apt, rating: MATCHING_MODE.NOPE };
        }

        return { ...apt, rating: MATCHING_MODE.NONE };
      } else {
        return { ...apt, rating: MATCHING_MODE.NONE };
      }
    });

    setApartmentList([...newApartmentList]);
  }, [isLoadingContent]);

  const handleApartmentDrawer = (apartmentId: string) => {
    setApartmentId(apartmentId);
    setShowApartment(true);
  };

  return (
    <PageLayout showStaticMenu showPageTitle={false} pageTitle={r(ROUTES.staticRoutes.organization.portfolio.title)}>
      {apartmentList && apartmentList.length && !isLoadingContent ? (
        <Grid item xs={12}>
          <div className={styles.logoWrapper}>
            {organization?.photoUrl && (
              <img
                className={styles.organizationImage}
                src={organization?.photoUrl}
                alt={`${organization?.title} Logo`}
              />
            )}
          </div>
          <PageTitle pageTitle={organization?.title} />
        </Grid>
      ) : null}

      <ApartmentDrawer
        isApplicationView={false}
        show={showApartment}
        close={setShowApartment}
        onClose={() => setShowApartment(false)}
        apartmentId={apartmentId}
      />

      {isLoadingContent ? (
        <Grid item xs={12}>
          {[1, 2, 3, 4, 5, 6].map((card, index) => (
            <Grid item key={index} xs={12} sm={6} md={4}>
              <ApartmentLoadingCard />
            </Grid>
          ))}
        </Grid>
      ) : !apartmentList?.length ? (
        <Headline size={HEADLINE_SIZE.H3}>{v('noApartments')}</Headline>
      ) : (
        <Grid spacing={2} container>
          {apartmentList.map((apartment) => {
            return (
              <Grid item key={apartment.id} xs={12} sm={6} lg={4}>
                <ApartmentCard
                  apartmentCardInformation={getApartmentCardInformationFromApartment(apartment)}
                  clickHandler={() => handleApartmentDrawer(apartment.id)}
                  isExclusive={apartment.isExclusive}
                />
              </Grid>
            );
          })}
        </Grid>
      )}
    </PageLayout>
  );
};

export default OrganizationPortfolioView;
