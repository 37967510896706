import styles from './PricingCard.module.scss';
import Text from '../../../atoms/typographie/Text';
import Headline, { HEADLINE_SIZE } from '../../../atoms/typographie/Headline';
import CheckList, { ICheckListItem } from '../../../atoms/CheckList';
import { FC, useContext, useState } from 'react';
import CTAButton, { BUTTON_STYLE } from '../../../atoms/Buttons/CTAButton';
import { PopupModal } from 'react-calendly';
import { USER_TYPE } from '@wohnsinn/ws-ts-lib';
import Spacer, { SPACER_SIZE } from '../../../atoms/Spacer';
import Badge, { BADGE_COLORS } from 'component/atoms/Badge';
import { MODAL_IDENTIFIER } from 'core/enum/modals.enum';
import ModalContext from 'core/context/modal.context';

export interface IPricingCardProps {
  badge?: string;
  checkListItems: ICheckListItem[];
  discount?: string;
  price: string;
  subLine?: string;
  title: string;
  titleExtra?: string;
  userType: USER_TYPE;
}

const PricingCard: FC<IPricingCardProps> = ({
  badge,
  checkListItems,
  discount,
  price,
  subLine,
  title,
  titleExtra,
  userType,
}) => {
  const [isCalendlyOpen, setIsCalendlyOpen] = useState(false);
  const { openModal } = useContext(ModalContext);

  return (
    <>
      <PopupModal
        url={process.env.REACT_APP_CALENDLY_CATCH_UP_LINK}
        onModalClose={() => setIsCalendlyOpen(false)}
        open={isCalendlyOpen}
        rootElement={document.getElementById('root')}
      />
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <div className={styles.info}>
            {badge ? (
              <div className={styles.badge}>
                <Badge text={badge} color={BADGE_COLORS.PRIMARY} />
              </div>
            ) : null}
            <Text>{title}</Text>
            <Headline tag={'span'} size={HEADLINE_SIZE.DISPLAY}>
              {price}
            </Headline>
            {subLine ? <Text>{subLine}</Text> : null}
            {discount ? <Text className={styles.discount}>{discount}</Text> : null}
            {titleExtra ? (
              <Text className={styles.titleExtra} tag={'span'}>
                {titleExtra}
              </Text>
            ) : null}
            <div className={styles.buttonWrapper}>
              <CTAButton
                size={'extra'}
                expand={'block'}
                rounded={false}
                onClick={() => openModal({ id: MODAL_IDENTIFIER.SELECT_USER_TYPE })}
                buttonText={userType === USER_TYPE.LANDLORD ? 'Kostenlos Objekt inserieren' : 'Wohnungssuche starten'}
                buttonStyle={BUTTON_STYLE.PRIMARY}
              />
            </div>
          </div>
        </div>
        <div className={styles.checkListWrapper}>
          <Headline size={HEADLINE_SIZE.H3}>FUNKTIONEN</Headline>
          <Spacer mobileSize={SPACER_SIZE.XS} />

          <CheckList items={checkListItems} />
        </div>
      </div>
    </>
  );
};

export default PricingCard;
