import { FC, PropsWithChildren, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/pro-light-svg-icons';
import styles from './ModalWrapper.module.scss';
import { useTranslation } from 'react-i18next';
import ModalContext from 'core/context/modal.context';
import Headline, { HEADLINE_SIZE } from 'component/atoms/typographie/Headline';
import Text, { TEXT_COLOR } from 'component/atoms/typographie/Text';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import CTAButton, { BUTTON_STYLE, ICTAButtonProps } from 'component/atoms/Buttons/CTAButton';
import Spacer, { SPACER_SIZE } from 'component/atoms/Spacer';

export interface IModalWrapperButtonProps {
  primary?: ICTAButtonProps;
  secondary?: ICTAButtonProps;
  third?: ICTAButtonProps;
}

export interface IModalWrapperProps {
  title?: string;
  subtitle?: string;
  noContentPadding?: boolean;
  icon?: string | IconProp;
  canClose?: boolean;
  buttons?: IModalWrapperButtonProps;
  modalFooter?: any;
}

const ModalWrapper: FC<PropsWithChildren<IModalWrapperProps>> = ({
  children,
  title,
  subtitle,
  noContentPadding = false,
  icon,
  canClose = true,
  buttons,
  modalFooter,
}) => {
  const { t: m } = useTranslation('modals');
  const { closeModal } = useContext(ModalContext);

  return (
    <div className={styles.modalWrapper}>
      <div className={styles.modalHeader}>
        <div />
        <div className={styles.titleWrapper}>
          {title ? (
            <Headline tag={'span'} size={HEADLINE_SIZE.H3} align={'center'}>
              {title}
            </Headline>
          ) : null}
        </div>

        {canClose ? (
          <button onClick={closeModal}>
            <FontAwesomeIcon icon={faClose} className={styles.closeIcon} />
            <span className={'sr-only'}>{m('close')}</span>
          </button>
        ) : null}
      </div>
      <div className={styles.modalChildren}>
        <div
          className={`${styles.childrenWrapper} ${subtitle ? styles.withSubtitle : ''} ${
            noContentPadding ? styles.noContentPadding : ''
          }`}
        >
          {subtitle ? (
            <>
              <Text color={TEXT_COLOR.TEXT_COLOR_ACCENT} tag={'span'} align={'center'}>
                {subtitle}
              </Text>
              <Spacer size={SPACER_SIZE.MD} />
            </>
          ) : null}
          {icon ? (
            typeof icon === 'string' ? (
              <img alt={'icon'} className={styles.icon} src={icon} />
            ) : (
              <div className={styles.iconWrapper}>
                <FontAwesomeIcon className={styles.icon} icon={icon} />
              </div>
            )
          ) : null}

          {children}
        </div>

        {buttons || modalFooter ? (
          <div className={styles.modalButtons}>
            {buttons?.primary ? (
              <CTAButton rounded={false} buttonStyle={BUTTON_STYLE.PRIMARY} {...buttons.primary} />
            ) : null}

            {buttons?.secondary ? (
              <CTAButton rounded={false} buttonStyle={BUTTON_STYLE.SECONDARY} {...buttons.secondary} />
            ) : null}

            {buttons?.third ? (
              <CTAButton
                rounded={false}
                color={TEXT_COLOR.TEXT_COLOR_DANGER}
                buttonStyle={BUTTON_STYLE.TERTIARY}
                size={'small'}
                {...buttons.third}
              />
            ) : null}

            {modalFooter}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ModalWrapper;
