import { FC } from 'react';
import styles from './LinkListItem.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/pro-light-svg-icons';
import Text, { TEXT_TYPE, TEXT_WEIGHT } from '../typographie/Text';
import { faEye } from '@fortawesome/pro-solid-svg-icons';
import { ILink } from '@wohnsinn/ws-ts-lib/dist/types/links';
import { ensureHttps } from 'core/context/ensure-https';

export interface IDocumentUploadListItemProps {
  link: ILink;
  deleteHandler?: any;
  previewHandler?: any;
  changeDescriptionHandler?: any;
}

const LinkListItem: FC<IDocumentUploadListItemProps> = ({
  link,
  deleteHandler,
  previewHandler,
  changeDescriptionHandler,
}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.label}>
        <img src={'/assets/icon/bookmark_pictogram.svg'} alt="open" />
        <div className={styles.nameWrapper}>
          <Text tag={'span'} weight={TEXT_WEIGHT.TEXT_WEIGHT_REGULAR} overFlowEllipsis>
            {link.alt}
          </Text>{' '}
          <Text tag={'span'} weight={TEXT_WEIGHT.TEXT_WEIGHT_LIGHT} type={TEXT_TYPE.TEXT_TYPE_CAPTION} overFlowEllipsis>
            ({link.url})
          </Text>
        </div>
      </div>
      <div className={styles.iconWrapper}>
        <a
          target={'_blank'}
          rel={'noreferrer noopener'}
          href={ensureHttps(link.url)}
          type={'button'}
          onClick={previewHandler}
        >
          <FontAwesomeIcon className={styles.icon} color={'black'} icon={faEye} />
        </a>

        {!!deleteHandler && (
          <button type={'button'} onClick={() => deleteHandler()}>
            <FontAwesomeIcon className={`${styles.icon} ${styles.delete}`} color={'black'} icon={faTrash} />
          </button>
        )}

        {!!changeDescriptionHandler && (
          <button type={'button'} onClick={changeDescriptionHandler}>
            <FontAwesomeIcon className={styles.icon} color={'black'} icon={faEdit} />
          </button>
        )}
      </div>
    </div>
  );
};

export default LinkListItem;
