import styles from './LPHeader.module.scss';
import CTAButton, { BUTTON_STYLE } from 'component/atoms/Buttons/CTAButton';
import { TEXT_COLOR } from 'component/atoms/typographie/Text';
import { MODAL_IDENTIFIER } from 'core/enum/modals.enum';
import { useContext } from 'react';
import ModalContext from 'core/context/modal.context';
import LPBurgerMenu from '../LPBurgerMenu';
import LPNavigation from '../LPNavigation';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const LPHeader = () => {
  const { t } = useTranslation('common');
  const location = useLocation();
  const { openModal } = useContext(ModalContext);

  const renderLogo = () => <div className={`${styles.logo} app-logo`} />;

  return (
    <header className={styles.wrapper}>
      <div className={styles.left}>
        {location.pathname === '/' ? (
          <button onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>{renderLogo()}</button>
        ) : (
          <Link to={'/'}>{renderLogo()}</Link>
        )}

        <div className={styles.hideMobile}>
          <LPNavigation />
        </div>
      </div>

      <div className={styles.mobileWrapper}>
        <div className={styles.buttonWrapper}>
          <CTAButton
            onClick={() => openModal({ id: MODAL_IDENTIFIER.SIGN_UP_USER, data: { isProbablyTenant: false } })}
            rounded={false}
            buttonStyle={BUTTON_STYLE.PRIMARY}
            buttonText={t('advertiseProperty')}
          />
          <CTAButton
            customStyling={styles.hideMobile}
            onClick={() => openModal({ id: MODAL_IDENTIFIER.SIGN_IN })}
            rounded={false}
            color={TEXT_COLOR.TEXT_COLOR_DARK}
            buttonStyle={BUTTON_STYLE.TERTIARY}
            buttonText={t('signIn')}
          />
          <CTAButton
            customStyling={styles.hideMobile}
            onClick={() => openModal({ id: MODAL_IDENTIFIER.SELECT_USER_TYPE })}
            rounded={false}
            color={TEXT_COLOR.TEXT_COLOR_DARK}
            buttonStyle={BUTTON_STYLE.TERTIARY}
            buttonText={t('createAccount')}
          />
        </div>
        <LPBurgerMenu />
      </div>
    </header>
  );
};

export default LPHeader;
