import {
  ABOUT_WS_ITEM,
  ACCOUNT_MENU_ITEM,
  ADMIN_MENU_ITEM,
  ADVERTISE_NOW_MENU_ITEM,
  APARTMENTS_MENU_ITEM,
  IMenuItemProps,
  IMenuNotifications,
  LANDLORD_ACCOUNT_MENU_ITEM,
  PRICES_ITEM,
  SEARCH_MENU_ITEM,
  SETTINGS_MENU_ITEM,
  TENANT_APPLICATIONS_MENU_ITEM,
  TENANT_MESSAGES_MENU_ITEM,
} from '../const/menus';

const isWogeEnvironment = process.env.REACT_APP_ENVIRONMENT === 'woge';

export const getTenantMessagesMenuItem = (notifications?: number): IMenuItemProps => {
  return {
    ...TENANT_MESSAGES_MENU_ITEM,
    notifications,
  };
};

export const getLandlordMessagesMenuItem = (notifications?: number): IMenuItemProps => {
  return {
    ...APARTMENTS_MENU_ITEM,
    notifications,
  };
};

export const getAccountMenuItem = (notifications?: boolean): IMenuItemProps => {
  return {
    ...ACCOUNT_MENU_ITEM,
    notifications: notifications,
  };
};
export const getLandlordAccountMenuItem = (notifications?: boolean): IMenuItemProps => {
  return {
    ...LANDLORD_ACCOUNT_MENU_ITEM,
    notifications: notifications,
  };
};

export const getTenantMenuList = (notifications?: IMenuNotifications): IMenuItemProps[] => {
  if (isWogeEnvironment) {
    return [
      SEARCH_MENU_ITEM,
      getAccountMenuItem(notifications.account),
      getTenantMessagesMenuItem(notifications.tenantChat),
      SETTINGS_MENU_ITEM,
    ];
  }
  return [
    SEARCH_MENU_ITEM,
    getAccountMenuItem(notifications.account),
    TENANT_APPLICATIONS_MENU_ITEM,
    getTenantMessagesMenuItem(notifications.tenantChat),
    SETTINGS_MENU_ITEM,
  ];
};

export const getLandlordMenuList = (notifications?: IMenuNotifications): IMenuItemProps[] => {
  const menu = [];
  menu.push(getLandlordMessagesMenuItem(notifications?.landlordChat));
  menu.push(getLandlordAccountMenuItem(notifications?.account));
  menu.push(SETTINGS_MENU_ITEM);

  return menu;
};

export const getAdminMenuList = (): IMenuItemProps => {
  return ADMIN_MENU_ITEM;
};

export const getGuestMenuList = (): IMenuItemProps[] => {
  const isWogeEnvironment = process.env.REACT_APP_ENVIRONMENT === 'woge';
  if (isWogeEnvironment) {
    return [SEARCH_MENU_ITEM, ABOUT_WS_ITEM];
  } else return [ADVERTISE_NOW_MENU_ITEM, SEARCH_MENU_ITEM, ABOUT_WS_ITEM, PRICES_ITEM];
};
