import ApartmentRatingButtons from 'component/atoms/ApartmentRatingButtons';
import LOCAL_STORAGE_KEYS from 'core/enum/local-storage.enum';
import { MODAL_IDENTIFIER } from 'core/enum/modals.enum';
import { getTenantApplicationState, TENANT_APPLICATION_STATE } from 'core/helper/get-tenant-application-state';
import { wohnsinnServices } from 'App';
import { Dispatch, FC, SetStateAction, useContext, useEffect, useState } from 'react';
import UserContext from 'core/context/user.context';
import ModalContext from 'core/context/modal.context';
import useTenantDocuments from 'core/hook/tenant-document.hook';
import { IApplication, IPaginatedApartmentsListResponse, MATCHING_MODE } from '@wohnsinn/ws-ts-lib';
import SnackBarContext from 'core/context/snackbar.context';
import { useQueryClient } from '@tanstack/react-query';
import TenantFilterParamsContext from 'core/context/tenant-filter-params.context';
import { InfiniteData } from '@tanstack/react-query/build/modern/index';
import styles from './ApartmentDrawer.module.scss';
import ApartmentInformation from 'component/organisms/ApartmentInformation';
import Headline, { HEADLINE_SIZE } from 'component/atoms/typographie/Headline';
import CTAButton, { BUTTON_STYLE } from 'component/atoms/Buttons/CTAButton';
import { faShareNodes } from '@fortawesome/pro-light-svg-icons';
import { useTranslation } from 'react-i18next';
import { faSpinner, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { Drawer } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TEXT_COLOR } from 'component/atoms/typographie/Text';
import { ROUTES } from 'core/const/routes';
import useDesiredTenant from 'core/hook/desired-tenant.hook';
import PremiumSubscriptionDrawer from 'component/atoms/PremiumSubscriptionDrawer';
import { useNavigate } from 'react-router-dom';
import useApartment from '../../../core/hook/apartment.hook';
import LoadingAnimation from 'component/atoms/LoadingAnimation';
import useWindowSize from 'core/hook/windowsize.hook';

interface IApartmentDrawerProps {
  show: boolean;
  close: Dispatch<SetStateAction<boolean>>;
  onClose: (closeDrawer: () => void, newData: any) => void;
  application?: IApplication;
  apartmentId: string;
  isApplicationView: boolean;
}

const ApartmentDrawer: FC<IApartmentDrawerProps> = ({
  close,
  onClose,
  show,
  application,
  apartmentId,
  isApplicationView,
}) => {
  const { apartment, isLoading } = useApartment(apartmentId);
  const queryClient = useQueryClient();
  const { handleSnackBar } = useContext(SnackBarContext);
  const { tenantFilterParams } = useContext(TenantFilterParamsContext);
  const { applicationService } = wohnsinnServices;
  const navigate = useNavigate();
  const { openModal } = useContext(ModalContext);
  const { user, isPremiumUser, tenantProfile } = useContext(UserContext);
  const { hasIncomeProofDocument, hasSchufaCheckDocument } = useTenantDocuments(tenantProfile?.uid);
  const [isCreatingRating, setIsCreatingRating] = useState(false);
  const { t: r } = useTranslation('routes');
  const { t } = useTranslation('common');
  const { mixpanelTrackingService } = wohnsinnServices;
  const { desiredTenantErrors } = useDesiredTenant(apartment, tenantProfile);
  const [showSubscriptionDrawer, setShowSubscriptionDrawer] = useState<boolean>(false);
  const showApplicationTunnel = Math.random() > 0.5;
  const { isSmallerSm } = useWindowSize();
  const [isAddressBarVisible, setIsAddressBarVisible] = useState(true);
  const [initialHeight, setInitialHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      const currentHeight = window.innerHeight;
      // Address bar is visible if the current height is less than the initial height
      setIsAddressBarVisible(currentHeight < initialHeight);
    };

    // Save the initial window height at the component mount
    setInitialHeight(window.innerHeight);

    // Add event listeners for resize and scroll
    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleResize);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleResize);
    };
  }, [initialHeight]);

  /**
   * Check if a user is logged in to either create application or save rating in localeStorage
   * @param {MATCHING_MODE} rating
   */
  const submitRatingHandler = async (rating: MATCHING_MODE) => {
    mixpanelTrackingService.trackRatingClick(rating, apartment.id);

    setIsCreatingRating(true);

    if (user) {
      if (rating === MATCHING_MODE.LIKE) {
        if (!user.isEmailVerified) {
          setTimeout(() => {
            openModal({ id: MODAL_IDENTIFIER.CONFIRM_EMAIL });
          }, 300);
          setIsCreatingRating(false);
          return;
        }

        const tenantApplicationState = getTenantApplicationState(tenantProfile);
        if (
          tenantApplicationState === TENANT_APPLICATION_STATE.MISSING_DOCS ||
          tenantApplicationState === TENANT_APPLICATION_STATE.NOT_READY_FOR_APPLICATION ||
          tenantApplicationState === TENANT_APPLICATION_STATE.READY_FOR_APPLICATION
        ) {
          localStorage.setItem(LOCAL_STORAGE_KEYS.REDIRECT_URL, '/');
        }
        if (tenantApplicationState === TENANT_APPLICATION_STATE.NOT_READY_FOR_APPLICATION) {
          setTimeout(() => {
            openModal({ id: MODAL_IDENTIFIER.COMPLETE_APPLICATION_FOLDER });
          }, 300);
          setIsCreatingRating(false);
          return;
        }

        if (desiredTenantErrors?.length) {
          openModal({ id: MODAL_IDENTIFIER.DESIRED_TENANT, data: desiredTenantErrors });
          setIsCreatingRating(false);
          return;
        }

        if (apartment?.isExclusive && !isPremiumUser) {
          setIsCreatingRating(false);
          setShowSubscriptionDrawer(true);
          return;
        }

        if (tenantApplicationState === TENANT_APPLICATION_STATE.MISSING_DOCS) {
          setTimeout(() => {
            openModal({ id: MODAL_IDENTIFIER.COMPLETE_DOCUMENT_FOLDER });
          }, 300);
          setIsCreatingRating(false);
          return;
        }

        if (tenantApplicationState === TENANT_APPLICATION_STATE.READY_FOR_APPLICATION) {
          setTimeout(() => {
            openModal({
              id: MODAL_IDENTIFIER.BEFORE_APPLICATION_OVERVIEW,
              data: {
                finalizeApplication: () => finalizeApplication(rating),
                hasIncomeProofDocument,
                hasSchufaCheckDocument,
              },
            });
          }, 300);
          setIsCreatingRating(false);
          return;
        }
      }

      await finalizeApplication(rating);
    } else {
      if (!user) {
        localStorage.setItem(LOCAL_STORAGE_KEYS.APARTMENT_ID, apartment?.id);
        mixpanelTrackingService.trackEvent('ABTest', { showApplicationTunnel });
        if (showApplicationTunnel) {
          navigate(
            r(ROUTES.staticRoutes.createTenantFromApartmentApplyTunnel.path).replace(':apartmentId', apartment?.id)
          );
          setIsCreatingRating(false);
          return;
        } else {
          openModal({
            id: MODAL_IDENTIFIER.SIGN_UP_USER,
            data: {
              title: 'Erstelle ein Konto, um dich zu bewerben',
              isProbablyTenant: true,
            },
          });

          setIsCreatingRating(false);
        }
      }
    }
    closeDrawer();
    // IF USER TRIES TO APPLY FORCE REGISTRATION / LOGIN
  };

  const closeDrawer = () => {
    close(false);
  };

  const removeApartmentFromCache = async () => {
    const data: InfiniteData<IPaginatedApartmentsListResponse> = queryClient.getQueryData([
      'matches',
      tenantFilterParams,
    ]);
    const newData = data;
    const newPages = data.pages;

    newPages.forEach((page) => {
      const index = page.apartments.findIndex((apt) => apt.id === apartment.id);
      if (index > -1) {
        page.apartments.splice(index, 1);
      }
    });
    newData.pages = newPages;

    setTimeout(async () => {
      onClose(closeDrawer, newData);
    }, 300);
  };

  const finalizeApplication = async (rating: MATCHING_MODE) => {
    try {
      await applicationService.handleRating(apartment, tenantProfile, rating, !application);

      if (rating === MATCHING_MODE.LIKE) {
        await applicationService.createApplication(apartment, tenantProfile, rating);
        handleSnackBar('toast.success.applied', 'info');
      }

      if (!application) {
        await removeApartmentFromCache();
      }

      setIsCreatingRating(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Drawer
      PaperProps={{
        sx: {
          borderTopLeftRadius: { xs: '10px', sm: '0' },
          borderTopRightRadius: { xs: '10px', sm: '10px' },
        },
      }}
      anchor={isSmallerSm ? 'bottom' : 'left'}
      open={show}
      onClose={() => close(false)}
    >
      {isLoading || !apartment ? (
        <div className={`${styles.drawer} ${styles.loadingWrapper}`}>
          <LoadingAnimation />
        </div>
      ) : apartment ? (
        <>
          {
            <Drawer open={showSubscriptionDrawer} anchor={'right'} sx={{ width: '100vw' }}>
              <PremiumSubscriptionDrawer closeAction={() => setShowSubscriptionDrawer(false)} />
            </Drawer>
          }
          <div
            className={`${styles.drawer} ${isAddressBarVisible ? styles.addressBarVisible : styles.addressBarHidden} `}
          >
            {isCreatingRating ? (
              <div className={styles.ratingInfo}>
                <FontAwesomeIcon size={'5x'} icon={faSpinner} spin={true} />
              </div>
            ) : null}

            <div className={styles.header}>
              <Headline size={HEADLINE_SIZE.H3} mobileSize={HEADLINE_SIZE.H2}>
                {apartment?.areas?.numberOfRooms ? `${apartment.areas.numberOfRooms}-Zimmer-` : ''}Wohnung
              </Headline>
              <div className={styles.buttonWrapper}>
                <CTAButton
                  size={'small'}
                  buttonStyle={BUTTON_STYLE.SECONDARY}
                  buttonText={t('share')}
                  hideText={true}
                  color={TEXT_COLOR.TEXT_COLOR_DARK}
                  onClick={() => openModal({ id: MODAL_IDENTIFIER.SHARE_LINK, data: { apartmentId: apartment.id } })}
                  icon={faShareNodes}
                />
                <CTAButton
                  size={'small'}
                  buttonStyle={BUTTON_STYLE.SECONDARY}
                  buttonText={t('close')}
                  color={TEXT_COLOR.TEXT_COLOR_DARK}
                  hideText={true}
                  onClick={() => close(false)}
                  icon={faTimes}
                />
              </div>
            </div>

            <div className={styles.body}>
              <ApartmentInformation apartment={apartment} />
            </div>

            <ApartmentRatingButtons
              isApplicationView={isApplicationView}
              apartment={apartment}
              disabled={isCreatingRating}
              onButtonClick={(matchingMode) => submitRatingHandler(matchingMode)}
            />
          </div>
        </>
      ) : null}
    </Drawer>
  );
};

export default ApartmentDrawer;
