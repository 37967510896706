import styles from './StatusBadge.module.scss';
import Badge, { BADGE_COLORS } from '../Badge';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { APPLICATION_STATE } from '@wohnsinn/ws-ts-lib';

export interface IStatusBadge {
  status: APPLICATION_STATE;
}

const StatusBadge: FC<IStatusBadge> = ({ status }) => {
  const { t } = useTranslation('common');

  return (
    <div className={styles.wrapper}>
      <Badge statusColor={getBadgeColor(status)} text={t(`applicationState.${status}`)} color={BADGE_COLORS.LIGHT} />
    </div>
  );
};

const getBadgeColor = (status: APPLICATION_STATE) => {
  switch (status) {
    case APPLICATION_STATE.DELETED:
    case APPLICATION_STATE.WILL_BE_DELETED:
      return BADGE_COLORS.DANGER;
    case APPLICATION_STATE.SELECTED_TENANT:
      return BADGE_COLORS.PRIMARY;
    default:
      return BADGE_COLORS.SUCCESS;
  }
};

export default StatusBadge;
